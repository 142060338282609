import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Control } from '../../models/interfaces/Control'

const initialState: Control = {
	avoidSave: false
}

const additionSlice = createSlice({
	name: 'control',
	initialState: initialState,
	reducers: {
		setControl: (state, action: PayloadAction<Control>) => {
			state.avoidSave = action.payload.avoidSave
		},
		resetControl: (state) => {
			state.avoidSave = initialState.avoidSave
		}
	}
})

export const { setControl, resetControl } = additionSlice.actions
export default additionSlice.reducer
