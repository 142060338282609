import { FC } from 'react'

// Styles
import './styles.css'

const Footer: FC = ({}) => {
	return (
		<div className="footer">
			<span>
				Powered by{' '}
				<a href="https://elesteam.com" target="_blank">
					Elesteam
				</a>{' '}
				• Copyright © Somenotes
			</span>
		</div>
	)
}

export default Footer
