import { en, es } from './supportedLanguages'

class LocalizationSelector {
	static getLocale() {
		const preferredLanguage = navigator.language.split('-')[0]
		return preferredLanguage
	}

	static getLocalizationDiccionary() {
		const locale = this.getLocale()
		const locale_prefix = locale.substring(0, 2)

		switch (locale_prefix) {
			case 'en':
				return en

			case 'es':
				return es

			default:
				return en
		}
	}
}

export default LocalizationSelector
