import React, { useCallback, useEffect, useState } from 'react'
import Category from '../../../models/interfaces/Category'
import { useAppDispatch } from '../../../redux/hooks'
import { setCategorySelected } from '../../../redux/slices/categoriesSlice'
import { setModification } from '../../../redux/slices/modificationSlice'
import {
	setCreateCategoryModalVisible,
	setDeleteModalVisible
} from '../../../redux/slices/modalsVisiblesSlice'
import TouchableOpacity from '../../primitive/touchableOpacity'
import View from '../../primitive/view'
import { setDeletion } from '../../../redux/slices/deletionSlice'
import LocalizationSelector from '../../../localization/localizationSelector'

// Styles
import Colors from '../../../assets/styles/css/general/colors'
import './styles.css'

// Images
const Dots3Options = require('../../../assets/images/buttons/3dots_vertical.png')

const CategoryDrawerOption = (props: {
	category: Category
	hasMenuOptions?: boolean
}) => {
	const dispatch = useAppDispatch()
	const [showOptions, setShowOptions] = useState(false)
	const [isHovered, setIsHovered] = useState(false)
	const locateDiccionary = LocalizationSelector.getLocalizationDiccionary()

	const setIsChecked = useCallback(() => {
		dispatch(setCategorySelected(props.category))
	}, [props.category, setCategorySelected])

	const showModifyCategoryModal = useCallback(
		(event: any) => {
			dispatch(setModification({ type: 'category', category: props.category }))
			dispatch(setCreateCategoryModalVisible(true))
			event.stopPropagation()
		},
		[props.category, setCreateCategoryModalVisible]
	)

	const on3DotsOptionClick = (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		setShowOptions(true)
		event.stopPropagation()
	}

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			setShowOptions(false)
		}

		window.addEventListener('click', handleClickOutside)

		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const handleHover = () => {
		setIsHovered(true)
	}

	const handleMouseLeave = () => {
		setIsHovered(false)
	}

	const handleDelete = useCallback(
		(event: any) => {
			dispatch(setDeletion({ type: 'category', id: props.category?.id ?? '' }))
			dispatch(setCreateCategoryModalVisible(false))
			dispatch(setDeleteModalVisible(true))
			event.stopPropagation()
		},
		[props.category]
	)

	return (
		<TouchableOpacity onPress={setIsChecked}>
			<View
				onMouseEnter={handleHover}
				onMouseLeave={handleMouseLeave}
				className="category_drawer_option_container"
				style={{
					backgroundColor: props.category.selected
						? Colors.PRIMARY_COLOR
						: Colors.VIEW_TAG
				}}
			>
				<View
					className="category_drawer_option_colorDot"
					style={{ backgroundColor: props.category.color }}
				/>
				<View
					className="category_drawer_option_containerName"
					style={{
						paddingBottom: props.category.selected ? 2 : 0,
						borderBottomWidth: props.category.selected ? 1 : 0
					}}
				>
					<b
						className="category_drawer_option_name"
						style={{
							fontWeight: props.category.selected ? '700' : '600',
							color: props.category.selected
								? Colors.PRIMARY_LIGHTTODARK_COLOR
								: Colors.PRIMARY_TEXT
						}}
					>
						{props.category.name}
					</b>
				</View>
				{!props.hasMenuOptions ? (
					<>
						<div className="category_drawer_option_container">
							{showOptions && (
								<ul className="category_drawer_option_options-list">
									<li onClick={(event) => showModifyCategoryModal(event)}>
										{locateDiccionary['edit']}
									</li>
									<li onClick={(event) => handleDelete(event)}>
										{locateDiccionary['delete']}
									</li>
								</ul>
							)}
						</div>
						{isHovered ? (
							<>
								<TouchableOpacity
									className="category_drawer_option_3dots"
									onPress={(event: any) => on3DotsOptionClick(event)}
								>
									<img
										src={Dots3Options}
										className="category_drawer_option_3dots_img"
									/>
								</TouchableOpacity>
							</>
						) : (
							<div style={{ height: 20, width: 20 }}></div>
						)}
					</>
				) : (
					<div style={{ height: 20, width: 20 }}></div>
				)}
			</View>
		</TouchableOpacity>
	)
}

export default CategoryDrawerOption
