import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Modification } from '../../models/interfaces/Modification'

const initialState: Modification = {
	type: '',
	note: undefined,
	category: undefined
}

const modificationSlice = createSlice({
	name: 'modification',
	initialState: initialState,
	reducers: {
		setModification: (state, action: PayloadAction<Modification>) => {
			state.type = action.payload.type
			state.note = action.payload.note
			state.category = action.payload.category
		},
		resetModification: (state) => {
			state.type = initialState.type
			state.note = initialState.note
			state.category = initialState.category
		}
	}
})

export const { setModification, resetModification } = modificationSlice.actions
export default modificationSlice.reducer
