import React from 'react'
import View from '../../primitive/view'

// Styles
import Colors from '../../../assets/styles/css/general/colors'
import './style.css'

interface AppBackgroundProps {
	icon?: any
	text: string
	backgroundColor?: string
	dot?: boolean
}

const CatagView: React.FC<AppBackgroundProps> = ({
	icon,
	text,
	backgroundColor,
	dot
}) => {
	const styles = {
		category_view_items_button_view_item_icon: {
			width: 14,
			height: 14,
			marginRight: 4,
			marginTop: 2
		},
		category_view_items_button_view_item_dot: {
			width: 6,
			height: 6,
			borderRadius: 6,
			backgroundColor: 'black',
			marginTop: 4,
			marginRight: 4
		}
	}

	return (
		<View
			className="category_view_items_button_view_item"
			style={{
				backgroundColor:
					backgroundColor && backgroundColor !== ''
						? backgroundColor
						: Colors.VIEW_TAG
			}}
		>
			{icon && (
				<img
					style={styles.category_view_items_button_view_item_icon}
					src={icon}
				/>
			)}
			{dot && <View style={styles.category_view_items_button_view_item_dot} />}
			<b
				className="category_view_items_button_view_item_text"
				style={{ color: dot ? 'black' : Colors.TEXT_TAG }}
			>
				{text}
			</b>
		</View>
	)
}

export default CatagView
