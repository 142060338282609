import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { ReactNode, useEffect, useState } from 'react'
import { ReactSession } from 'react-client-session'
import { useAppDispatch, useAppSelector } from './app/redux/hooks'
import { setUser } from './app/redux/slices/userSlice'
import ProfileModalModal from './app/components/templates/modals/profileModal'

// VIEWS
import Auth from './app/views/auth'
import Notes from './app/views/notes'

// COMPONENTS
import Navbar from './app/components/templates/navbar'
import Footer from './app/components/templates/footer'

// MODELS
import User from './app/models/interfaces/User'

// MODALS
import ModalHelp from './app/components/templates/modals/modalHelp'

// STYLE
import './App.css'

// REDUX provider
import { Provider } from 'react-redux'
import { store } from './app/redux/store'

interface AuthContextProps {
	children: ReactNode
}
const AuthContext: React.FC<AuthContextProps> = ({ children }) => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		checkSession()
	}, [])

	function checkSession() {
		const sessionUser = ReactSession.get('user') as User
		if (sessionUser !== undefined) {
			if (sessionUser.email !== '') {
				dispatch(setUser(sessionUser))
			}
		}
	}
	return <>{children}</>
}

interface MainAppProps {
	children: ReactNode
}
const MainApp: React.FC<MainAppProps> = ({}) => {
	const user = useAppSelector((state) => state.user)

	// STATES
	const [displayHelpModal, setDisplayHelpModal] = useState(false)
	const [displayModalProfile, setDisplayModalProfile] = useState(false)

	function showHelpModal() {
		setDisplayHelpModal(true)
	}

	function hideHelpModal() {
		setDisplayHelpModal(false)
	}

	function showProfileModal() {
		setDisplayModalProfile(true)
	}

	function hideProfileModal() {
		setDisplayModalProfile(false)
	}

	return (
		<>
			<div className="App">
				<div className="background_div">
					<div className="container">
						<Navbar
							showHelpModal={showHelpModal}
							showProfileModal={showProfileModal}
						/>

						<div className="AppContent">
							<Routes>
								<Route
									path="/"
									element={
										user.email === '' ? (
											<Navigate to="/login" />
										) : (
											<Navigate to="/notes" />
										)
									}
								/>
								<Route
									path="/login"
									element={
										user.email === '' ? <Auth /> : <Navigate to="/notes" />
									}
								/>
								<Route
									path="/notes"
									element={
										user.email === '' ? <Navigate to="/login" /> : <Notes />
									}
								/>
							</Routes>
						</div>
					</div>
				</div>
			</div>

			<Footer />

			{displayHelpModal ? (
				<ModalHelp visible={displayHelpModal} onCancel={hideHelpModal} />
			) : (
				<></>
			)}
			{displayModalProfile && (
				<ProfileModalModal
					visible={displayModalProfile}
					onCancel={hideProfileModal}
				/>
			)}
		</>
	)
}

function App() {
	return (
		<BrowserRouter>
			<Provider store={store}>
				<AuthContext>
					<MainApp>
						<></>
					</MainApp>
				</AuthContext>
			</Provider>
		</BrowserRouter>
	)
}

export default App
