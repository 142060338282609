import { FC, useState } from 'react'
import { ReactSession } from 'react-client-session'
import { useNavigate } from 'react-router-dom'
import { auth, database } from '../../../firebaseConfig'
import {
	UserCredential,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signInWithEmailAndPassword
} from 'firebase/auth'
import { ref, get, query, push } from 'firebase/database'
import LoadingSpinner from '../../components/atoms/loadingSpinner'
import { validateCreateAccount } from '../../helpers/ValidationHelper'
import { useAppDispatch } from '../../redux/hooks'
import { setUser } from '../../redux/slices/userSlice'
import LocalizationSelector from '../../localization/localizationSelector'
import User from '../../models/interfaces/User'

// STYLES
import './styles.css'

// IMAGES
const app_icon = require('../../assets/images/app/Icon512.png')
const face_thong = require('../../assets/images/faces/img_face_thong.png')
const face_surprice = require('../../assets/images/faces/img_face_surprised.png')
const app_slogan = require('../../assets/images/app/somenotes_new_header_black_text.png')

const Auth: FC = () => {
	const locateDiccionary = LocalizationSelector.getLocalizationDiccionary()

	// NAVIGATOR
	const navigate = useNavigate()

	// CONTEXT
	const dispatch = useAppDispatch()

	// STATES
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [username, setUsername] = useState<string>('')
	const [lgshow, setLgshow] = useState<'LOGIN' | 'SIGNUP' | 'FORGETPASSWORD'>(
		'LOGIN'
	)
	const [loading, setLoading] = useState<boolean>(false)
	const [message, setMessage] = useState<string>('')
	const [emailSent, setEmailSent] = useState(false)

	// FUNCTIONS CORE -----------------------------------------------
	function toogleToLogin() {
		setLgshow('LOGIN')
		setMessage('')
		setLoading(false)
	}
	function toogleToSignup() {
		setLgshow('SIGNUP')
		setMessage('')
		setLoading(false)
	}

	function toogleToForgotPassword() {
		setLgshow('FORGETPASSWORD')
		setMessage('')
		setLoading(false)
	}

	async function performLogin() {
		if (email.length == 0 || password.length == 0) {
			setMessage(locateDiccionary['type_email_and_password'])
			return
		}

		setLoading(true)

		signInWithEmailAndPassword(auth, email, password)
			.then(async (userCredential: UserCredential) => {
				// GETTING USER DATA
				const databaseReference = ref(
					database,
					'/' + userCredential.user.uid + '/'
				)

				get(query(databaseReference))
					.then(async (userData) => {
						const _userInfo: User = {
							email: '',
							name: '',
							surname: '',
							uid: ''
						}

						userData.child('user').forEach((_userDataObj) => {
							const _userInfoData: any = _userDataObj.toJSON()

							_userInfo.email = userCredential.user.email ?? ''
							_userInfo.name = _userInfoData!.name ?? ''
							_userInfo.surname = _userInfoData!.surname ?? ''
							_userInfo.uid = userCredential.user.uid ?? ''
						})
						if (_userInfo.email !== '' && _userInfo.uid !== '') {
							//LOGGED
							dispatch(setUser(_userInfo))
							ReactSession.set('user', _userInfo)
							navigate('/notes')
						} else {
							_userInfo.email = userCredential.user.email ?? ''
							_userInfo.name = userCredential.user.email ?? ''
							_userInfo.surname = userCredential.user.email ?? ''
							_userInfo.uid = userCredential.user.uid ?? ''

							ReactSession.set(
								'user',
								new User(
									email,
									_userInfo.name,
									_userInfo.name,
									_userInfo.surname,
									_userInfo.uid
								)
							)
							dispatch(setUser(_userInfo))
							navigate('/notes')
						}
					})
					.catch(() => {
						setMessage(locateDiccionary['unable_to_retrieve_user_data'])
					})
			})
			.catch((err) => {
				switch (err.code) {
					case 'auth/user-not-found':
						setMessage(locateDiccionary['user_not_found'])
						break
					case 'auth/wrong-password':
						setMessage(locateDiccionary['email_or_password_invalid'])
						break

					default:
						setMessage(locateDiccionary['error_trying_to_logging'])
				}
				setLoading(false)
			})
	}

	// CREATE ACCOUNT
	function performSignUp() {
		setMessage('')

		if (!validateCreateAccount(email, username, password)) {
			setMessage(locateDiccionary['complete_all_fields'])
			return
		}

		setLoading(true)

		const [name, surname] = username.split(' ')

		createUserWithEmailAndPassword(auth, email, password)
			.then((userCredential: UserCredential) => {
				const user = userCredential.user
				const databaseReference = ref(database, '/' + user.uid + '/user/')

				push(databaseReference, {
					name,
					surname
				})
					.then(async () => {
						dispatch(
							setUser({
								email: email,
								name: name,
								surname: surname,
								uid: user.uid
							})
						)
						ReactSession.set(
							'user',
							new User(email, username, name, surname, user.uid)
						)
						navigate('/notes')
					})
					.catch(() => {
						setMessage(locateDiccionary['error_trying_to_register'])
					})
					.finally(() => {
						setLoading(false)
					})
			})
			.catch((error: any) => {
				switch (error.code) {
					case 'auth/wrong-password':
						setMessage(locateDiccionary['wrong_password'])
						break
					case 'auth/invalid-email':
						setMessage(locateDiccionary['email_invalid_format'])
						break
					case 'auth/weak-password':
						setMessage(locateDiccionary['password_too_week'])
						break
					case 'auth/email-already-in-use':
						setMessage(locateDiccionary['email_already_registered'])
						break

					default:
						setMessage(locateDiccionary['error_registering'])
				}

				setLoading(false)
			})
	}

	async function performForgotPassword() {
		if (email.length == 0) {
			setMessage(locateDiccionary['type_your_email_please'])
			return
		}

		setLoading(true)

		sendPasswordResetEmail(auth, email)
			.then(() => {
				setEmailSent(true)
			})
			.catch((err) => {
				switch (err.code) {
					case 'auth/user-not-found':
						setMessage(locateDiccionary['user_not_found'])
						break

					default:
						setMessage(locateDiccionary['error_sending_forgot_email'])
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<div className="view_auth_login_container">
			<div className="view_auth_login-signup_div">
				<button
					className={
						lgshow === 'LOGIN'
							? 'view_auth_tab-button view_auth_tab-button-active'
							: 'view_auth_tab-button'
					}
					onClick={() => {
						toogleToLogin()
					}}
				>
					{locateDiccionary['login']}
				</button>
				<button
					className={
						lgshow === 'SIGNUP'
							? 'view_auth_tab-button view_auth_tab-button-active'
							: 'view_auth_tab-button'
					}
					onClick={() => {
						toogleToSignup()
					}}
				>
					{locateDiccionary['signup']}
				</button>
			</div>

			{lgshow === 'LOGIN' && (
				<div className="view_auth_login-div">
					<img src={app_icon} className="view_auth_form-img-main" />
					<img src={app_slogan} className="view_auth_form-img_slogan" />
					<h3 className="view_auth_form-title">{locateDiccionary['login']}</h3>
					<h3 className="view_auth_form-subtitle">
						{locateDiccionary['continue_taking_notes']}
					</h3>
					<input
						type="email"
						readOnly={loading}
						value={email}
						placeholder={locateDiccionary['email']}
						className="view_auth_form-input"
						onChange={(val) => {
							setEmail(val.target.value)
						}}
					/>
					<input
						type="password"
						readOnly={loading}
						value={password}
						placeholder={locateDiccionary['password']}
						className="view_auth_form-input"
						onChange={(val) => {
							setPassword(val.target.value)
						}}
					/>
					<b>{message}</b>
					<b
						className="view_auth_forgot-password-btn"
						onClick={() => {
							toogleToForgotPassword()
						}}
					>
						{locateDiccionary['forgot_password']}
					</b>
					<button
						className="view_auth_btn-submit"
						onClick={() => {
							performLogin()
						}}
					>
						{locateDiccionary['login']}
					</button>
				</div>
			)}
			{lgshow === 'SIGNUP' && (
				<div className="view_auth_signup-div">
					<img src={face_thong} className="view_auth_form-img" />
					<img src={app_slogan} className="view_auth_form-img_slogan" />
					<h3 className="view_auth_form-title">
						{locateDiccionary['signup_now']}
					</h3>
					<h3 className="view_auth_form-subtitle">
						{locateDiccionary['and_start_writing']}
					</h3>
					<input
						type="email"
						readOnly={loading}
						value={email}
						placeholder={locateDiccionary['email']}
						className="view_auth_form-input"
						onChange={(val) => {
							setEmail(val.target.value)
						}}
					/>
					<input
						type="username"
						readOnly={loading}
						value={username}
						placeholder={locateDiccionary['fullname']}
						className="view_auth_form-input"
						onChange={(val) => {
							setUsername(val.target.value)
						}}
					/>
					<input
						type="password"
						readOnly={loading}
						value={password}
						placeholder={locateDiccionary['password']}
						className="view_auth_form-input"
						onChange={(val) => {
							setPassword(val.target.value)
						}}
					/>
					<button
						className="view_auth_btn-submit"
						onClick={() => {
							performSignUp()
						}}
					>
						{locateDiccionary['signup']}
					</button>
				</div>
			)}
			{lgshow === 'FORGETPASSWORD' && (
				<div className="view_auth_signup-div">
					<img src={face_surprice} className="view_auth_form-img" />
					<img src={app_slogan} className="view_auth_form-img_slogan" />
					<h3 className="view_auth_form-title">
						{locateDiccionary['forgot_password']}
					</h3>
					<h3 className="view_auth_form-subtitle">
						{locateDiccionary['lets_recover_account']}
					</h3>
					{!emailSent ? (
						<>
							<input
								type="email"
								readOnly={loading}
								value={email}
								placeholder={locateDiccionary['email']}
								className="view_auth_form-input"
								onChange={(val) => {
									setEmail(val.target.value)
								}}
							/>

							<button
								className="view_auth_btn-submit"
								onClick={() => {
									performForgotPassword()
								}}
							>
								{locateDiccionary['reset_password']}
							</button>
						</>
					) : (
						<>
							<b className="view_auth_email_sent_message">
								{locateDiccionary['if_email_exists']}
							</b>
						</>
					)}
				</div>
			)}
			<b className="view_auth_message_b_error">{message}</b>

			{loading ? (
				<div className="view_auth_div_spinner_container">
					<LoadingSpinner />
				</div>
			) : (
				<></>
			)}
		</div>
	)
}

export default Auth
