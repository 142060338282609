import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'
import LocalizationSelector from '../../../localization/localizationSelector'

// Styles
import './styles.css'

// Images
const info_icon = require('../../../assets/images/buttons/info_icon.png')
const icon_profile = require('../../../assets/images/buttons/icon_profile.png')

interface NavBarProps {
	showHelpModal?: () => void
	showProfileModal?: () => void
}

const Navbar: React.FC<NavBarProps> = ({ showHelpModal, showProfileModal }) => {
	const locateDiccionary = LocalizationSelector.getLocalizationDiccionary()
	const user = useAppSelector((state) => state.user)

	return (
		<div className="navbar_div">
			<button
				className="navbar_button_styled"
				onClick={() => {
					showHelpModal && showHelpModal()
				}}
			>
				<img
					className="navbar_profile_icon"
					src={info_icon}
					style={{ opacity: 0.5 }}
				/>
				<b className="navbar_profile_name">{locateDiccionary['information']}</b>
			</button>
			{user.email !== '' ? (
				<button
					className="navbar_button_styled"
					onClick={() => {
						showProfileModal && showProfileModal()
					}}
				>
					<img
						className="navbar_profile_icon"
						src={icon_profile}
						style={{ opacity: 0.5, width: 20, height: 20, padding: 8 }}
					/>
					<b className="navbar_profile_name">{user.name}</b>
				</button>
			) : (
				<Link to="/login">
					<button className="navbar_button_styled">
						<img
							className="navbar_profile_icon"
							src={icon_profile}
							style={{ opacity: 0.5, width: 20, height: 20, padding: 8 }}
						/>
						<b className="navbar_profile_name">
							{locateDiccionary['login_s_signup']}
						</b>
					</button>
				</Link>
			)}
		</div>
	)
}

export default Navbar
