import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { setCreateCategoryModalVisible } from '../../../../redux/slices/modalsVisiblesSlice'
import LocalizationSelector from '../../../../localization/localizationSelector'
import Modal from '../../../primitive/modal'
import View from '../../../primitive/view'
import ScrollView from '../../../primitive/scrollView'
import TouchableOpacity from '../../../primitive/touchableOpacity'

// Styles
import './styles.css'
import Colors from '../../../../assets/styles/css/general/colors'

type SelectCategoryModalProps = {
	visible: boolean
	onSave: (
		newSelectedCategory?: string,
		newSelectedCategoryName?: string,
		newSelectedCategoryColor?: string
	) => void
	onCancel: () => void
	selectedCategory?: string
}

const SelectCategoryModal: React.FC<SelectCategoryModalProps> = ({
	visible,
	onCancel,
	onSave,
	selectedCategory
}) => {
	const [selectedValue, setSelectedValue] = useState<string | undefined>(
		selectedCategory
	)
	const [selectedLabel, setSelectedLabel] = useState<string | undefined>('')
	const [selectedColor, setSelectedColor] = useState<string | undefined>('')
	const categories = useAppSelector((state) => state.categories)
	const dispatch = useAppDispatch()
	const locateDiccionary = LocalizationSelector.getLocalizationDiccionary()

	useEffect(() => {
		selectedCategory && setSelectedValue(selectedCategory)
	}, [visible])

	const handleOptionSelect = useCallback(
		(value: string, label: string, color: string) => {
			setSelectedValue(value)
			setSelectedLabel(label)
			setSelectedColor(color)
		},
		[setSelectedValue, setSelectedLabel, setSelectedColor]
	)

	const handleSave = useCallback(() => {
		onSave && onSave(selectedValue, selectedLabel, selectedColor)
	}, [onSave, selectedValue, selectedLabel])

	const showAddCategoryModal = useCallback(() => {
		onCancel()
		dispatch(setCreateCategoryModalVisible(true))
	}, [onCancel, setCreateCategoryModalVisible])

	return (
		<Modal
			visible={visible}
			animationType="fade"
			transparent={true}
			className="select_category_modal_containers"
		>
			<View className="select_category_modal_modalContainer">
				<View className="select_category_modal_modalCard">
					<b className="select_category_modal_modalTitle">
						{locateDiccionary['select_category']}
					</b>
					<ScrollView
						className="select_category_modal_optionsScrollview"
						showsHorizontalScrollIndicator={false}
						showsVerticalScrollIndicator={false}
					>
						<View className="select_category_modal_optionsScrollviewContainer">
							<TouchableOpacity
								className="select_category_modal_option"
								key={'cate-none'}
								onPress={() => {
									handleOptionSelect(
										'',
										locateDiccionary['none'],
										'transparent'
									)
								}}
							>
								<View
									className="select_category_modal_option_container"
									style={{
										backgroundColor:
											'' === selectedValue
												? Colors.PRIMARY_COLOR
												: 'transparent'
									}}
								>
									<View
										className="select_category_modal_colorDot"
										style={{ backgroundColor: 'transparent' }}
									/>
									<b className="select_category_modal_optionText">
										{locateDiccionary['none']}
									</b>
								</View>
							</TouchableOpacity>
							{categories.map((option) => (
								<>
									{!option.default && (
										<TouchableOpacity
											className="select_category_modal_option"
											key={option.id}
											onPress={() => {
												handleOptionSelect(option.id, option.name, option.color)
											}}
										>
											<View
												className="select_category_modal_option_container"
												style={{
													backgroundColor:
														option.id === selectedValue
															? Colors.PRIMARY_COLOR
															: 'transparent'
												}}
											>
												<View
													className="select_category_modal_colorDot"
													style={{ backgroundColor: option.color }}
												/>
												<b className="select_category_modal_optionText">
													{option.name}
												</b>
											</View>
										</TouchableOpacity>
									)}
								</>
							))}
							<TouchableOpacity
								key={'-1'}
								onPress={() => {
									showAddCategoryModal()
								}}
								className="select_category_modal_optionTextAddNew-button"
							>
								<b className="select_category_modal_optionTextAddNew">
									{locateDiccionary['add_new_category']}
								</b>
							</TouchableOpacity>
						</View>
					</ScrollView>
					<View className="select_category_modal_bottom_buttons_container">
						<TouchableOpacity
							className="select_category_modal_modal-button-secondary"
							onPress={onCancel}
						>
							<b className="select_category_modal_modal-button-secondary">
								{locateDiccionary['cancel']}
							</b>
						</TouchableOpacity>
						<TouchableOpacity
							className="select_category_modal-button"
							onPress={handleSave}
						>
							<b className="select_category_modal_modal-button">
								{locateDiccionary['save']}
							</b>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	)
}

export default SelectCategoryModal
