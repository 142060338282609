import React, { CSSProperties, ReactNode } from 'react'

interface ModalProps {
	children?: ReactNode
	style?: CSSProperties
	className?: string
	visible?: boolean
	animationType?: string
	transparent?: boolean
}

const Modal: React.FC<ModalProps> = ({
	children,
	style,
	className,
	visible,
	animationType,
	transparent
}) => {
	const mergedStyle: CSSProperties = {
		...style
	}
	if (!visible) {
		return <></>
	}
	return (
		<div className={className} style={mergedStyle}>
			{children}
		</div>
	)
}

export default Modal
