import React, { CSSProperties, ReactNode } from 'react'

interface TouchableOpacityProps {
	children: ReactNode
	style?: CSSProperties
	onPress?: (event?: any) => any
	className?: string
	disabled?: boolean
}

const TouchableOpacity: React.FC<TouchableOpacityProps> = ({
	children,
	style,
	onPress,
	className,
	disabled
}) => {
	const mergedStyle: CSSProperties = {
		// ...style,
		backgroundColor: 'transparent',
		borderWidth: 0,
		userSelect: 'text',
		cursor: 'pointer',
		padding: 0,
		margin: 0
	}
	return (
		<button
			className={className}
			style={mergedStyle}
			onClick={onPress}
			disabled={disabled}
		>
			{children}
		</button>
	)
}

export default TouchableOpacity
