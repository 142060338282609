import View from '../../primitive/view'

// Styles
import Colors from '../../../assets/styles/css/general/colors'
import './styles.css'

const TagView = (props: { tagName: string }) => {
	return (
		<View style={styles.tagContainer}>
			<b style={styles.tagName} className="tag_view_b_tag">
				# {props.tagName}
			</b>
		</View>
	)
}

const styles = {
	tagContainer: {
		backgroundColor: Colors.VIEW_TAG,
		borderRadius: 10,
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 10,
		paddingRight: 10,
		marginLeft: 4,
		marginBottom: 4
	},
	tagName: {
		fontWeight: '400',
		color: Colors.TEXT_TAG
	}
}

export default TagView
