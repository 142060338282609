import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import User from '../../models/interfaces/User'
// import User from '../../models/interfaces/User'

const initialState: User = {
	email: '',
	name: '',
	surname: '',
	uid: '',
	active: false,
	creationDateString: '2022-10-17T04:18:03.888Z',
	createdBy: '',
	modificationDateString: '2022-10-17T04:18:03.888Z',
	modifiedBy: '',
	photo_url: ''
}

const userSlice = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User>) => {
			;(state.email = action.payload.email),
				(state.name = action.payload.name),
				(state.surname = action.payload.surname),
				(state.uid = action.payload.uid),
				(state.active = action.payload.active),
				(state.creationDateString = action.payload.creationDateString),
				(state.createdBy = action.payload.createdBy),
				(state.modificationDateString = action.payload.modificationDateString),
				(state.modifiedBy = action.payload.modifiedBy),
				(state.photo_url = action.payload.photo_url)
		},
		resetUser: (state) => {
			state.email = initialState.email
			state.name = initialState.name
			state.surname = initialState.surname
			state.uid = initialState.uid
			state.active = initialState.active
			state.creationDateString = initialState.creationDateString
			state.createdBy = initialState.createdBy
			state.modificationDateString = initialState.modificationDateString
			state.modifiedBy = initialState.modifiedBy
			state.photo_url = initialState.photo_url
		}
	}
})

export const { resetUser, setUser } = userSlice.actions
export default userSlice.reducer
