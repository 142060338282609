import { FC, useCallback, useEffect, useState } from 'react'
import { ReactSession } from 'react-client-session'
import LoadingSpinner from '../../components/atoms/loadingSpinner'
import ModalCreateEditNote from '../../components/templates/modals/modalCreateEditNote'
import Note from '../../models/interfaces/Note'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setVisualization } from '../../redux/slices/visualizationSlice'
import Tag from '../../models/interfaces/Tag'
import { setTags } from '../../redux/slices/tagsSlice'
import {
	get,
	query,
	ref,
	orderByChild,
	startAt,
	endAt
} from 'firebase/database'
import { database } from '../../../firebaseConfig'
import { setNotes } from '../../redux/slices/notesSlice'
import Category from '../../models/interfaces/Category'
import { setCategories } from '../../redux/slices/categoriesSlice'
import { groupByAttribute, groupByTagId } from '../../helpers/arrayHelpers'
import { resetAddition } from '../../redux/slices/additionSlice'
import SafeAreaView from '../../components/primitive/safeAreaView'
import View from '../../components/primitive/view'
import TouchableOpacity from '../../components/primitive/touchableOpacity'
import ScrollView from '../../components/primitive/scrollView'
import NoteCardView from '../../components/molecules/noteCardView'
import ModalsComponent from '../../components/organisms/modalsComponent'
import LeftDrawer from '../../components/templates/leftDrawer'
import LocalizationSelector from '../../localization/localizationSelector'

// STYLES
import './styles.css'
import '../../assets/styles/css/general/globalStyles.css'
import { setControl } from '../../redux/slices/controlSlice'

// IMAGES
const CreateNewNoteIcon = require('../../assets/images/buttons/icon_add_note.png')
const VisualizationIconlist = require('../../assets/images/buttons/visualization_list.png')
const VisualizationIconCategory = require('../../assets/images/buttons/visualization_category.png')
const VisualizationIconTag = require('../../assets/images/buttons/visualization_tag.png')
const img_empty = require('../../assets/images/misc/empty_image.png')
const img_add_new_note = require('../../assets/images/buttons/icon_add_note.png')

const Notes: FC = ({}) => {
	// STATES
	const [isLoading, setIsLoading] = useState(true)
	const [isFeatching, setIsFeatching] = useState(true)
	const [isCategoriesFetched, setIsCategoriesFetched] = useState(false)
	const [filteredNotes, setFilteredNotes] = useState<Note[]>()
	const [filteredAgroupedNotesByCategory, setFilteredAgroupedNotesByCategory] =
		useState<Map<any, Note[]>>()
	const [filteredAgroupedNotesByTag, setFilteredAgroupedNotesByTag] =
		useState<Map<any, Note[]>>()
	const locateDiccionary = LocalizationSelector.getLocalizationDiccionary()
	const user = useAppSelector((state) => state.user)
	const notes = useAppSelector((state) => state.notes)
	const categories = useAppSelector((state) => state.categories)
	const tags = useAppSelector((state) => state.tags)
	const vizualitation = useAppSelector((state) => state.visualization)
	const [displayModalCreateEditNote, setDisplayModalCreateEditNote] =
		useState(false)
	const visualization = useAppSelector((state) => state.visualization)
	const [workingNote, setWorkingNote] = useState<Note>({
		id: '',
		title: '',
		content: '',
		categoryId: '',
		tags: []
	})

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (user != undefined) {
			if (user.email != undefined && user.email != '') {
				console.log(`About to get CATEGORIES | --------------`)
				getCategories()
			}
		}
	}, [user])

	useEffect(() => {
		checkVisualizationPreference()
	}, [])

	const checkVisualizationPreference = useCallback(async () => {
		try {
			const _pref = ReactSession.get('ASYNC_STORAGE_KEY_PREF_VIZ') as string
			if (_pref != null) {
				switch (_pref) {
					case 'full-list':
						dispatch(setVisualization('full-list'))
						break
					case 'tag-list':
						dispatch(setVisualization('tag-list'))
						break
					case 'category-list':
						dispatch(setVisualization('category-list'))
						break

					default:
						dispatch(setVisualization('full-list'))
				}
			}
		} catch (err) {
			console.log('Error visualization: ', err)
		}
	}, [])

	useEffect(() => {
		updateTags()
	}, [notes])

	useEffect(() => {
		if (notes.length > 0 && !isFeatching) {
			console.log(`About to FILTER NOTES | --------------`)
			filterNotes()
			filterAgroupedNotesByCategory()
			filterAgroupedNotesByTag()
			setIsLoading(false)
		}
		if (!isFeatching && notes.length === 0) {
			setIsLoading(false)
		}
	}, [notes, tags, categories, isFeatching, vizualitation.visualization])

	// TO DEBUG ###################################################
	// useEffect(() => {
	// 	console.log(`notes CHANGED | --------------`)
	// }, [notes])

	// useEffect(() => {
	// 	console.log(`categories CHANGED | --------------`)
	// }, [categories])

	// useEffect(() => {
	// 	console.log(`tags CHANGED | --------------`)
	// }, [tags])

	// useEffect(() => {
	// 	console.log(`isFeatching CHANGED | --------------`)
	// }, [isFeatching])

	// useEffect(() => {
	// 	console.log(`vizualitation CHANGED | --------------`)
	// }, [vizualitation])

	const updateTags = useCallback(() => {
		const tagsSet = new Set<string>()

		notes.forEach((note) => {
			note.tags?.forEach((noteTag) => {
				tagsSet.add(noteTag)
			})
		})

		const _tags: Tag[] = Array.from(tagsSet).map((tagString) => ({
			name: tagString,
			selected: false,
			default: false
		}))

		dispatch(setTags(_tags))
	}, [notes])

	const getNotes = async () => {
		const databaseReference = ref(database, '/notes/' + user.uid)

		try {
			const data = await get(
				query(
					databaseReference,
					orderByChild('deleted'),
					startAt(null),
					endAt(false)
				)
			)

			const _dataJSON: any = data.toJSON()
			const _notes: Note[] = []

			for (const key in _dataJSON) {
				if (_dataJSON.hasOwnProperty(key)) {
					let arrayOfTags: string[] = []
					try {
						arrayOfTags = Object.values(_dataJSON[key]?.tags) || []
					} catch {}
					let _category = ''
					if (_dataJSON[key]?.categoryId && _dataJSON[key]?.categoryId !== '') {
						const foundCategory = categories.find(
							(cat) => cat.id === _dataJSON[key]?.categoryId
						)
						if (foundCategory) {
							_category = foundCategory.id
						}
					}

					_notes.push({
						id: key,
						title: _dataJSON[key]?.title || '',
						content: _dataJSON[key]?.content || '',
						date: _dataJSON[key]?.date || '',
						tags: arrayOfTags,
						categoryId: _category,
						modificationDate: _dataJSON[key]?.modificationDate || ''
					})
				}
			}

			dispatch(setNotes(_notes))
			setIsFeatching(false)
		} catch (err) {
			console.log(`err x|=========>`, JSON.stringify(err))
		}
	}

	const getCategories = async () => {
		const databaseReference = ref(database, '/categories/' + user.uid)

		try {
			const data = await get(
				query(
					databaseReference,
					orderByChild('deleted'),
					startAt(null),
					endAt(false)
				)
			)
			const _dataJSON: any = data.toJSON()
			const _categories: Category[] = []

			for (const key in _dataJSON) {
				if (_dataJSON.hasOwnProperty(key)) {
					_categories.push({
						id: key,
						name: _dataJSON[key]?.name || '',
						color: _dataJSON[key]?.color || '',
						selected: false,
						default: false,
						modificationDate: _dataJSON[key]?.modificationDate || ''
					})
				}
			}

			console.log(`About to dispatch CATEGORIES | --------------`)
			dispatch(setCategories(_categories))
			setIsCategoriesFetched(true)
		} catch (err) {
			console.log(`err c|=========>`, err)
			setIsCategoriesFetched(true)
		}
	}

	useEffect(() => {
		if (
			user.uid !== undefined &&
			user.uid !== '' &&
			isCategoriesFetched &&
			notes.length === 0
		) {
			console.log(`About to get NOTES | --------------`)
			getNotes()
		}
	}, [categories, isCategoriesFetched])

	const filterNotes = useCallback(() => {
		if (vizualitation.visualization !== 'full-list') {
			return
		}
		console.log(`FILTERING AGROUPING NOTES NORMAL | --------------`)
		let _notes: Note[] = []

		notes.forEach((note) => {
			categories.forEach((category) => {
				tags.forEach((tag) => {
					if (
						((category.default && category.selected) ||
							(category.selected && note.categoryId === category.id)) &&
						((tag.default && tag.selected) ||
							(tag.selected && note.tags?.includes(tag.name)))
					) {
						if (!_notes.includes(note)) {
							_notes.push(note)
						}
					}
				})
			})
		})
		setFilteredNotes(_notes)
	}, [notes, tags, categories, vizualitation])

	const filterAgroupedNotesByCategory = useCallback(() => {
		if (vizualitation.visualization !== 'category-list') {
			return
		}
		console.log(`FILTERING AGROUPING NOTES BY CATEGORY | --------------`)
		let _notes: Note[] = []

		notes.map((note) => {
			categories.map((category) => {
				tags.map((tag) => {
					if (
						((category.default && category.selected) ||
							(category.selected && note.categoryId === category.id)) &&
						((tag.default && tag.selected) ||
							(tag.selected && note.tags?.includes(tag.name)))
					) {
						if (!_notes.includes(note)) {
							_notes.push(note)
						}
					}
				})
			})
		})

		const __notes = groupByAttribute(_notes, 'categoryId')
		setFilteredAgroupedNotesByCategory(__notes)
	}, [notes, categories, tags, vizualitation])

	const filterAgroupedNotesByTag = useCallback(() => {
		if (vizualitation.visualization !== 'tag-list') {
			return
		}
		console.log(`FILTERING AGROUPING NOTES BY TAG | --------------`)
		let _notes: Note[] = []
		let _tag_selected: string = 'All'

		notes.map((note) => {
			categories.map((category) => {
				tags.map((tag) => {
					if (tag.selected) {
						_tag_selected = tag.name
					}
					if (
						((category.default && category.selected) ||
							(category.selected && note.categoryId === category.id)) &&
						((tag.default && tag.selected) ||
							(tag.selected && note.tags?.includes(tag.name)))
					) {
						if (!_notes.includes(note)) {
							_notes.push(note)
						}
					}
				})
			})
		})

		const __notes = groupByTagId(_notes, _tag_selected)
		setFilteredAgroupedNotesByTag(__notes)
	}, [notes, categories, tags, vizualitation])

	const getCategoryColor = useCallback(
		(categoryid: string) => {
			const category = categories.find((category) => category.id === categoryid)

			if (category) {
				return category.color
			}

			return 'transparent'
		},
		[categories]
	)

	const getCategoryName = useCallback(
		(categoryId: string) => {
			const category = categories.find((category) => category.id === categoryId)

			if (category) {
				return category.name
			}

			return locateDiccionary['uncategorized']
		},
		[categories, locateDiccionary]
	)

	const goToWriteNote = useCallback((note?: Note) => {
		dispatch(resetAddition())
		dispatch(setControl({ avoidSave: false }))
		if (note) {
			setWorkingNote(note)
			setDisplayModalCreateEditNote(true)
		} else {
			setWorkingNote({
				id: '',
				title: '',
				content: ''
			})
			setDisplayModalCreateEditNote(true)
		}
	}, [])

	function hideCreateEditNoteModal() {
		setDisplayModalCreateEditNote(false)
	}

	const changeVisualizationMode = useCallback(() => {
		const nextVisualization =
			visualization.visualization === 'full-list'
				? 'category-list'
				: visualization.visualization === 'category-list'
				? 'tag-list'
				: 'full-list'

		dispatch(setVisualization(nextVisualization))
	}, [visualization.visualization])

	return (
		<View className="notes_main_container">
			<View className="notes_main_container_drawer">
				<LeftDrawer />
			</View>
			<View className="view_notes_container_notes">
				<SafeAreaView className="view_notes_container">
					<View className="view_notes_global_container">
						<View className="view_notes_left_container"></View>
						<View className="view_notes_global_title">
							<b className="view_notes_listHeaderTitle">
								{locateDiccionary['notes']}
							</b>
						</View>
						<View className="view_notes_global_buttons_container">
							<View className="view_notes_global_buttons_container_inside">
								<TouchableOpacity
									onPress={() => {
										changeVisualizationMode()
									}}
								>
									<View className="view_notes_global_buttons_container_secondary">
										<img
											src={
												visualization.visualization === 'full-list'
													? VisualizationIconlist
													: visualization.visualization === 'category-list'
													? VisualizationIconCategory
													: visualization.visualization === 'tag-list'
													? VisualizationIconTag
													: VisualizationIconlist
											}
											className="view_notes_global_buttons_container_secondary_icon"
										/>
									</View>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => {
										goToWriteNote()
									}}
								>
									<View className="view_notes_global_buttons_container_primary">
										<img
											src={CreateNewNoteIcon}
											className="view_notes_global_buttons_container_primary_icon"
										/>
										<b className="view_notes_global_buttons_container_primary_text">
											{locateDiccionary['new_note'].toUpperCase()}
										</b>
									</View>
								</TouchableOpacity>
							</View>
						</View>
					</View>
					{!isLoading && notes.length === 0 && (
						<View className="view_notes_emptyImagenContainer">
							<img src={img_empty} className="view_notes_emptyImagen" />
							<text className="view_notes_emptyImagenText">
								{locateDiccionary['empty']}
							</text>
							<View className="view_notes_empty_button_text_description">
								<b className="view_notes_emptyImagenSubText">
									{locateDiccionary['start_taking_note_left']}
								</b>
								<TouchableOpacity onPress={() => goToWriteNote()}>
									<img
										src={img_add_new_note}
										className="view_notes_emptyImagenSubTextImage"
									/>
								</TouchableOpacity>
								<b className="view_notes_emptyImagenSubText">
									{locateDiccionary['button']}
								</b>
							</View>
						</View>
					)}
					{!isLoading && notes.length > 0 && (
						<>
							{vizualitation.visualization === 'full-list' ? (
								<View>
									<ScrollView style={{ height: '100%' }}>
										{filteredNotes && filteredNotes.length > 0 ? (
											filteredNotes.map((note, index) => {
												return (
													<TouchableOpacity
														className="view_notes_card_buttoned_complete"
														key={index + note.id}
														onPress={() => goToWriteNote(note)}
													>
														<View
															style={{
																width:
																	vizualitation.visualization === 'full-list'
																		? '100%'
																		: 500
															}}
														>
															<NoteCardView
																note={note}
																key={note.id}
																titleNChars={200}
																contentNChars={400}
															/>
														</View>
													</TouchableOpacity>
												)
											})
										) : (
											<View>
												<b>{locateDiccionary['empty']}</b>
											</View>
										)}
									</ScrollView>
								</View>
							) : vizualitation.visualization === 'category-list' ? (
								<View>
									<ScrollView style={{ height: '100%' }}>
										{filteredAgroupedNotesByCategory !== undefined &&
											Array.from(
												filteredAgroupedNotesByCategory?.entries()
											).map(([categoryid, notesx]) => (
												<View>
													<View
														className="view_notes_categoryname_oncarousel_view"
														style={{
															backgroundColor: getCategoryColor(categoryid)
														}}
													>
														<b
															className="view_notes_categoryname_oncarousel_text"
															style={{
																color: categoryid !== '' ? 'black' : '#5e5e5e'
															}}
														>
															{getCategoryName(categoryid)}
														</b>
													</View>
													<View className="view_notes_categoryname_oncarousel_view_container">
														{notesx.map((note, index) => (
															<TouchableOpacity
																className="view_notes_card_buttoned"
																key={index + note.id}
																onPress={() => goToWriteNote(note)}
															>
																<View
																	className="view_notes_card_buttoned_part_container"
																	style={{
																		width:
																			vizualitation.visualization ===
																			'full-list'
																				? '100%'
																				: '100%'
																	}}
																>
																	<NoteCardView
																		note={note}
																		contentNLines={6}
																		titleNLines={2}
																		key={note.id}
																	/>
																</View>
															</TouchableOpacity>
														))}
													</View>
												</View>
											))}
									</ScrollView>
								</View>
							) : (
								<View>
									<ScrollView style={{ height: '100%' }}>
										{filteredAgroupedNotesByTag !== undefined &&
											Array.from(filteredAgroupedNotesByTag?.entries()).map(
												([noteTag, notesx]) => (
													<View>
														{noteTag && (
															<View
																className="view_notes_categoryname_oncarousel_view"
																style={{
																	backgroundColor: '#8181812d'
																}}
															>
																<b
																	className="view_notes_categoryname_oncarousel_text"
																	style={{
																		color: '#5e5e5e'
																	}}
																>
																	{noteTag !== 'Untagged' ? '#' : ''} {noteTag}
																</b>
															</View>
														)}

														<ScrollView className="view_notes_categoryname_oncarousel_view_container">
															{notesx.map((note, index) => (
																<TouchableOpacity
																	className="view_notes_card_buttoned"
																	key={index + note.id}
																	onPress={() => goToWriteNote(note)}
																>
																	<View
																		style={{
																			width:
																				vizualitation.visualization ===
																				'full-list'
																					? '100%'
																					: '100%'
																		}}
																	>
																		<NoteCardView
																			note={note}
																			contentNLines={6}
																			titleNLines={2}
																			key={note.id}
																		/>
																	</View>
																</TouchableOpacity>
															))}
														</ScrollView>
													</View>
												)
											)}
									</ScrollView>
								</View>
							)}
						</>
					)}
					{displayModalCreateEditNote && (
						<ModalCreateEditNote
							hideModalService={hideCreateEditNoteModal}
							note={workingNote}
						/>
					)}

					<ModalsComponent
						hideCreateEditNoteModal={() => {
							setDisplayModalCreateEditNote(false)
						}}
					/>
					{isLoading && (
						<div className="view_notes_div_spinner_container">
							<LoadingSpinner />
						</div>
					)}
				</SafeAreaView>
			</View>
		</View>
	)
}

export default Notes
