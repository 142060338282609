import React, { useCallback } from 'react'
import {
	addCategory,
	removeCategory,
	updateCategory
} from '../../../redux/slices/categoriesSlice'
import { resetDeletion } from '../../../redux/slices/deletionSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
	setCreateCategoryModalVisible,
	setDeleteModalVisible
} from '../../../redux/slices/modalsVisiblesSlice'
import { refreshNotes, removeNote } from '../../../redux/slices/notesSlice'
import { removeTag } from '../../../redux/slices/tagsSlice'
import CreateCategoryModal from '../../templates/modals/createCategoryModal'
import DeleteModal from '../../templates/modals/deleteModal'
import { setAddition } from '../../../redux/slices/additionSlice'
import { resetModification } from '../../../redux/slices/modificationSlice'
import { database } from '../../../../firebaseConfig'
import { ref, push, set, update } from 'firebase/database'
import { setControl } from '../../../redux/slices/controlSlice'

interface ModalsComponentProps {
	hideCreateEditNoteModal?: () => void
}

const ModalsComponent: React.FC<ModalsComponentProps> = ({
	hideCreateEditNoteModal
}) => {
	const { createCategoryModalVisible, deleteModalVisible } = useAppSelector(
		(state) => state.modalsvisibles
	)
	const deletion = useAppSelector((state) => state.deletion)
	const user = useAppSelector((state) => state.user)
	const dispatch = useAppDispatch()

	const onCreateCategorySave = useCallback(
		(
			name: string,
			color: string,
			modifyingCategory?: boolean,
			modifyingCategoryId?: string
		) => {
			const currentDate = new Date()
			const formattedDate = currentDate
				.toISOString()
				.slice(0, 19)
				.replace('T', ' ')

			if (modifyingCategory && modifyingCategoryId) {
				const databaseReference = ref(
					database,
					'/categories/' + user.uid + '/' + modifyingCategoryId
				)

				set(databaseReference, {
					name: name,
					color: color,
					modificationDate: formattedDate
				})
					.then((key) => {
						dispatch(
							updateCategory({
								id: modifyingCategoryId,
								name: name,
								selected: false,
								default: false,
								color: color,
								modificationDate: formattedDate
							})
						)
					})
					.catch((err) => {
						console.log(`err 1|=========>`, JSON.stringify(err))
					})
			} else {
				const randomInteger = Math.floor(Math.random() * 100)
				const randomString = randomInteger.toString()
				const databaseReference = ref(database, '/categories/' + user.uid + '/')

				push(databaseReference, {
					name: name,
					color: color,
					modificationDate: formattedDate
				})
					.then((key) => {
						dispatch(
							addCategory({
								id: key.key ?? randomString,
								name: name,
								selected: false,
								default: false,
								color: color,
								modificationDate: formattedDate
							})
						)
						dispatch(
							setAddition({
								type: 'category',
								id: key.key ?? randomString
							})
						)
					})
					.catch((err) => {
						console.log(`err 2|=========>`, JSON.stringify(err))
					})
			}

			dispatch(setCreateCategoryModalVisible(false))
			dispatch(resetModification())
			dispatch(resetDeletion())
		},
		[user, database]
	)

	const onCreateCategoryCancel = useCallback(() => {
		dispatch(setCreateCategoryModalVisible(false))
		dispatch(resetModification())
		dispatch(resetDeletion())
	}, [])

	const onDelete = useCallback(() => {
		switch (deletion.type) {
			case 'category':
				const databaseReferenceCategory = ref(
					database,
					'/categories/' + user.uid + '/' + deletion.id
				)
				update(databaseReferenceCategory, { deleted: true })
					.then(() => {
						dispatch(removeCategory(deletion.id))
						dispatch(refreshNotes(deletion.id))
					})
					.catch((err) => {
						console.log(`err 3|=========>`, JSON.stringify(err))
					})
					.finally(() => {
						dispatch(setDeleteModalVisible(false))
						dispatch(resetDeletion())
						dispatch(resetModification())
					})
				break
			case 'note':
				const databaseReference = ref(
					database,
					'/notes/' + user.uid + '/' + deletion.id
				)
				update(databaseReference, { deleted: true })
					.then(() => {
						dispatch(setControl({ avoidSave: true }))
						dispatch(removeNote(deletion.id))
						hideCreateEditNoteModal && hideCreateEditNoteModal()
					})
					.finally(() => {
						dispatch(setDeleteModalVisible(false))
						dispatch(resetDeletion())
						dispatch(resetModification())
					})
				break
			case 'tag':
				dispatch(removeTag(deletion.id))
				break
			default:
		}
	}, [deletion, user])

	const onDeleteCancel = useCallback(() => {
		dispatch(resetDeletion())
		dispatch(resetModification())
		dispatch(setDeleteModalVisible(false))
	}, [])

	return (
		<>
			{createCategoryModalVisible && (
				<CreateCategoryModal
					visible={createCategoryModalVisible}
					onSave={onCreateCategorySave}
					onCancel={onCreateCategoryCancel}
					colorSelector={true}
				/>
			)}
			{deleteModalVisible && (
				<DeleteModal
					visible={deleteModalVisible}
					onDelete={onDelete}
					onCancel={onDeleteCancel}
				/>
			)}
		</>
	)
}

export default ModalsComponent
