import React, { CSSProperties, ReactNode } from 'react'

interface SafeAreaViewProps {
	children: ReactNode
	style?: CSSProperties
	className?: string
}

const SafeAreaView: React.FC<SafeAreaViewProps> = ({
	children,
	style,
	className
}) => {
	const mergedStyle: CSSProperties = {
		...style
	}
	return (
		<div className={className} style={mergedStyle}>
			{children}
		</div>
	)
}

export default SafeAreaView
