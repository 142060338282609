import Note from '../models/interfaces/Note'

export function groupByAttribute<T>(
	array: T[],
	attribute: keyof T
): Map<any, T[]> {
	return array.reduce((acc, item) => {
		const key = item[attribute]
		if (!acc.has(key)) {
			acc.set(key, [])
		}
		acc.get(key).push(item)
		return acc
	}, new Map())
}

export function groupByTagId(
	array: Note[],
	selected_tag: string
): Map<string, Note[]> {
	let map = new Map<string, Note[]>()

	let ya = false
	array.forEach((note) => {
		ya = false
		note.tags?.forEach((tag) => {
			if (selected_tag === 'All' || tag === selected_tag) {
				ya = true
				map.set(tag, [...(map.get(tag) ?? []), note])
			}
		})
		if (!ya) {
			map.set('Untagged', [...(map.get('Untagged') ?? []), note])
		}
	})

	return map
}
