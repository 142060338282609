import React, { useCallback } from 'react'
import LocalizationSelector from '../../../../localization/localizationSelector'
import Modal from '../../../primitive/modal'
import View from '../../../primitive/view'
import TouchableOpacity from '../../../primitive/touchableOpacity'

// Styles
import './styles.css'

type DeleteModalProps = {
	visible: boolean
	onDelete: () => void
	onCancel: () => void
}

const DeleteModal: React.FC<DeleteModalProps> = ({
	visible,
	onDelete,
	onCancel
}) => {
	const locateDiccionary = LocalizationSelector.getLocalizationDiccionary()

	const handleSave = useCallback(() => {
		onDelete()
	}, [])

	return (
		<Modal
			visible={visible}
			animationType="fade"
			transparent={true}
			className="delete_modal_containers"
		>
			<View className="delete_modal_modalContainer">
				<View className="delete_modal_modalCard">
					<b className="delete_modal_modalTitle">
						{locateDiccionary['are_you_sure_delete']}
					</b>
					<View className="delete_modal_bottom_buttons_container ">
						<TouchableOpacity
							className="delete_modal_modal-button_container"
							onPress={handleSave}
						>
							<b className="delete_modal_modal-button">
								{locateDiccionary['yes']}
							</b>
						</TouchableOpacity>
						<TouchableOpacity
							className="delete_modal_modal-button-secondary"
							onPress={onCancel}
						>
							<b className="delete_modal_modal-button-secondary">
								{locateDiccionary['cancel']}
							</b>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	)
}

export default DeleteModal
