import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Deletion } from '../../models/interfaces/Deletion'

const initialState: Deletion = {
	type: '',
	id: ''
}

const deletionSlice = createSlice({
	name: 'deletion',
	initialState: initialState,
	reducers: {
		setDeletion: (state, action: PayloadAction<Deletion>) => {
			state.type = action.payload.type
			state.id = action.payload.id
		},
		resetDeletion: (state) => {
			state.type = initialState.type
			state.id = initialState.id
		}
	}
})

export const { setDeletion, resetDeletion } = deletionSlice.actions
export default deletionSlice.reducer
