import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ModalsVisibles from '../../models/interfaces/ModalsVisibles'

const initialState: ModalsVisibles = {
	createCategoryModalVisible: false,
	deleteModalVisible: false
}

const modalsVisiblesSlice = createSlice({
	name: 'modalsvisibles',
	initialState: initialState,
	reducers: {
		setCreateCategoryModalVisible: (state, action: PayloadAction<boolean>) => {
			state.createCategoryModalVisible = action.payload
		},
		setDeleteModalVisible: (state, action: PayloadAction<boolean>) => {
			state.deleteModalVisible = action.payload
		},
		resetModalsVisibles: (state) => {
			state.createCategoryModalVisible = initialState.createCategoryModalVisible
			state.deleteModalVisible = initialState.deleteModalVisible
		}
	}
})

export const {
	setCreateCategoryModalVisible,
	setDeleteModalVisible,
	resetModalsVisibles
} = modalsVisiblesSlice.actions

export default modalsVisiblesSlice.reducer
