const Appearance = {
	getColorScheme: () => {
		return 'light'
	}
}

class Colors {
	static PRIMARY_COLOR = '#0ba7ff'

	static PRIMARY_LIGHTTODARK_COLOR =
		Appearance.getColorScheme() === 'dark' ? '#000' : '#ffffff'
	static SECONDARY_LIGHTTODARK_COLOR =
		Appearance.getColorScheme() === 'dark' ? '#000000' : '#f0f0f0'

	static PRIMARY_DARKTOLIGHT_COLOR =
		Appearance.getColorScheme() === 'dark' ? '#FFF' : '#000'
	static SECONDARY_DARKTOLIGHT_COLOR =
		Appearance.getColorScheme() === 'dark' ? '#f0f0f0' : '#1a1a1a'

	static CONTAINER_COLOR = Colors.PRIMARY_LIGHTTODARK_COLOR
	static SUBCONTAINER_COLOR = Colors.SECONDARY_LIGHTTODARK_COLOR

	static TITLE_COLOR = Appearance.getColorScheme() === 'dark' ? '#CCC' : '#000'
	static LABEL = Colors.TITLE_COLOR
	static HEADER_COLOR = Colors.PRIMARY_LIGHTTODARK_COLOR

	static LOADING_INDICATOR_BACKGROUND = Colors.PRIMARY_LIGHTTODARK_COLOR

	static INPUT_TEXT_PLACEHOLDER = Colors.TITLE_COLOR
	static INPUT_TEXT_BACKGROUND =
		Appearance.getColorScheme() === 'dark' ? '#333' : '#EEE'
	static INPUT_TEXT_BORDER =
		Appearance.getColorScheme() === 'dark' ? '#222' : '#DDDDDD'

	static SHADOW_COLOR = Colors.PRIMARY_LIGHTTODARK_COLOR
	static SHADOW_COLOR_FIXED = '#707070'

	static CARD_BACKGROUND_COLOR = Colors.PRIMARY_LIGHTTODARK_COLOR
	static CARD_TEXT = Appearance.getColorScheme() === 'dark' ? '#777' : '#000'

	static SECONDARY_THING_COLOR =
		Appearance.getColorScheme() === 'dark' ? '#444' : '#CDCDCD'
	static BUTTON_SECONDARY_COLOR = Colors.SECONDARY_THING_COLOR
	static BUTTON_SECONDARY_TEXT_COLOR = Colors.SECONDARY_THING_COLOR
	static BUTTON_DANGER_COLOR = Colors.SECONDARY_THING_COLOR
	static BUTTON_DANGER_TEXT_COLOR =
		Appearance.getColorScheme() === 'dark' ? '#c90000' : '#ff2525'

	static CARD_TABLE_TILES =
		Appearance.getColorScheme() === 'dark' ? '#111' : '#FFF'
	static CARD_TABLE_TILES_TEXT =
		Appearance.getColorScheme() === 'dark' ? '#AAA' : '#555'

	static MODAL_BORDER_COLOR =
		Appearance.getColorScheme() === 'dark' ? '#333' : '#DDD'

	static BORDER_COLOR = '#88888848'

	static INPUT_TEXT_BACKGROUND_SOLID = Colors.PRIMARY_LIGHTTODARK_COLOR
	static INPUT_TEXT_PLACEHOLDER_SOLID =
		Appearance.getColorScheme() === 'dark' ? '#CCC' : '#747474'
	static INPUT_TEXT_BORDER_SOLID =
		Appearance.getColorScheme() === 'dark' ? '#525252' : '#b9b9b9'

	static PLACEHOLDER_COLOR_EDITOR =
		Appearance.getColorScheme() === 'dark' ? '#919191' : '#949494'

	static PRIMARY_TEXT =
		Appearance.getColorScheme() === 'dark' ? '#dfdfdf' : '#000'
	static SUBTITLE_TEXT =
		Appearance.getColorScheme() === 'dark' ? '#dfdfdf' : '#000000'
	static TEXT_NEGOTAIBLE =
		Appearance.getColorScheme() === 'dark' ? '#006bb3' : '#00ccff'
	static TEXT_COLOR_1 =
		Appearance.getColorScheme() === 'dark' ? '#6b6b6b' : '#000000'
	static TEXT_COLOR_DISABLED =
		Appearance.getColorScheme() === 'dark' ? '#6b6b6b' : '#c5c5c5'

	static TAB_TILE_INACTIVE =
		Appearance.getColorScheme() === 'dark' ? '#919191' : '#535353'

	static TEXT_AMBIENTED_BLUE =
		Appearance.getColorScheme() === 'dark' ? '#006bb3' : '#00ccff'
	static TEXT_AMBIENTED_ORANGE =
		Appearance.getColorScheme() === 'dark' ? '#c90000' : '#ff2525'

	static BUTTON_ARROW_COLOR = 'rgba(255,255,255,0.7)'
	static WHITE_OPACITY_50 = 'rgba(255,255,255,0.5)'
	static WHITE_OPACITY_100 = 'rgba(255,255,255,1)'

	static VIEW_TAG = '#8181812d'
	static TEXT_TAG = '#5e5e5e'
}

export default Colors
