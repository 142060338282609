import React, { useEffect, useMemo, useState } from 'react'
import Note from '../../../models/interfaces/Note'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import TagView from '../../atoms/tagView'
import View from '../../primitive/view'
import { setDeleteModalVisible } from '../../../redux/slices/modalsVisiblesSlice'
import { setDeletion } from '../../../redux/slices/deletionSlice'
import { truncateToNCharacters } from '../../../helpers/stringHelper'
import { ContentState, convertFromHTML } from 'draft-js'

// Styles
import './styles.css'

// Images
const DeleteIcon = require('../../../assets/images/buttons/icon_delete.png')

const NoteCardView = (props: {
	note: Note
	titleNLines?: number
	contentNLines?: number
	titleNChars?: number
	contentNChars?: number
}) => {
	const tags = useAppSelector((state) => state.tags)
	const categories = useAppSelector((state) => state.categories)
	const [isHovered, setIsHovered] = useState(false)
	const [content, setContent] = useState('')

	useEffect(() => {
		if (ContentState !== undefined) {
			const htmlString = props.note.content
			const blocksFromHTML = convertFromHTML(htmlString)
			const contentState = ContentState.createFromBlockArray(
				blocksFromHTML.contentBlocks
			)
			let plainText = ''

			contentState.getBlockMap().forEach((block) => {
				plainText += block ? block.getText() + '\n' : ''
			})
			setContent(plainText)
		}
	}, [props.note])

	const dispatch = useAppDispatch()

	const tagsNames = useMemo(() => {
		let names: string[] = []
		tags.map((tag) => {
			if (props.note?.tags) {
				if (props.note.tags.includes(tag.name)) {
					if (!names.includes(tag.name)) {
						names.push(tag.name)
					}
				}
			}
		})
		return names
	}, [tags, props.note])

	const categoryColor = useMemo(() => {
		let color = ''
		categories.map((category) => {
			if (props.note?.categoryId) {
				if (category.id === props.note.categoryId) {
					color = category.color
				}
			}
		})
		return color
	}, [categories, props.note])

	const handleHover = () => {
		setIsHovered(true)
	}

	const handleMouseLeave = () => {
		setIsHovered(false)
	}

	const onDeleteClick = (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		dispatch(
			setDeletion({
				type: 'note',
				id: props.note.id
			})
		)
		dispatch(setDeleteModalVisible(true))

		event.stopPropagation()
	}

	return (
		<View className="note_card_view_container" key={props.note.id}>
			<View className="note_card_view_card">
				<View className="note_card_view_card_title_and_description">
					{props.note.title.length > 0 && (
						<b className="note_card_view_title">
							{truncateToNCharacters(
								props.note.title,
								props.titleNChars ? props.titleNChars : 500
							)}
						</b>
					)}
					<View className="note_card_view_contentContainer">
						<b className="note_card_view_content">
							{truncateToNCharacters(
								content,
								props.contentNChars ? props.contentNChars : 1000
							)}
						</b>
					</View>
				</View>
				<View
					className="note_card_view_tags_and_options"
					onMouseEnter={handleHover}
					onMouseLeave={handleMouseLeave}
				>
					<View className="note_card_view_tags_container">
						{tagsNames.map((tagName) => (
							<TagView tagName={tagName} key={tagName} />
						))}
					</View>
					<View className="note_card_view_options_container">
						{!isHovered ? (
							<b className="note_card_view_options_modified_date">
								{props.note.modificationDate}
							</b>
						) : (
							<View className="note_card_view_options">
								<a
									className="note_card_view_options_option"
									onClick={(event) => onDeleteClick(event)}
								>
									<img
										src={DeleteIcon}
										className="note_card_view_options_option_image"
									/>
								</a>
							</View>
						)}
					</View>
				</View>

				<View
					className="note_card_view_categoryColor"
					style={{
						backgroundColor:
							categoryColor && categoryColor !== ''
								? categoryColor
								: 'transparent'
					}}
				></View>
			</View>
		</View>
	)
}

export default NoteCardView
