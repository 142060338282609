// Styles
import './styles.css'

// Images
const SomenotesNewHeader = require('../../../assets/images/app/somenotes_new_header_black_text.png')

const AppDrawerHeader = () => {
	return (
		<>
			<img
				className="app_drawer_header_image"
				src={SomenotesNewHeader}
				style={styles.imageLogo}
			/>
		</>
	)
}

const styles = {
	imageLogo: {
		width: '70%',
		resizeMode: 'center',
		height: 70,
		alignSelf: 'center'
	}
}

export default AppDrawerHeader
