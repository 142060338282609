import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { setCreateCategoryModalVisible } from '../../../redux/slices/modalsVisiblesSlice'
import CategoryDrawerOption from '../../atoms/categoryDrawerOption'
import TagDrawerOption from '../../atoms/tagDrawerOption'
import AppDrawerHeader from '../../organisms/appDrawerHeader'
import { resetAddition } from '../../../redux/slices/additionSlice'
import { resetModification } from '../../../redux/slices/modificationSlice'
import { resetDeletion } from '../../../redux/slices/deletionSlice'
import LocalizationSelector from '../../../localization/localizationSelector'
import View from '../../primitive/view'
import SafeAreaView from '../../primitive/safeAreaView'
import ScrollView from '../../primitive/scrollView'
import TouchableOpacity from '../../primitive/touchableOpacity'

// Styles
import './styles.css'

// Images
const IconAdd = require('../../../assets/images/buttons/icon_add.png')

function LeftDrawer() {
	const tags = useAppSelector((state) => state.tags)
	const categories = useAppSelector((state) => state.categories)
	const dispatch = useAppDispatch()
	const locateDiccionary = LocalizationSelector.getLocalizationDiccionary()

	const showCreateCategoryModal = useCallback(() => {
		dispatch(resetAddition())
		dispatch(resetModification())
		dispatch(resetDeletion())
		dispatch(setCreateCategoryModalVisible(true))
	}, [])

	return (
		<View
			style={{
				flex: 1,
				paddingTop: 20,
				backgroundColor: 'white',
				borderRightColor: '#cccccc',
				borderRightWidth: 0,
				borderRightStyle: 'solid'
			}}
		>
			<SafeAreaView>
				<ScrollView
					className="left_drawer_scrollView"
					showsHorizontalScrollIndicator={false}
					showsVerticalScrollIndicator={false}
				>
					<AppDrawerHeader />
					<View className="left_drawer_categoriesTitleContainer">
						<b className="left_drawer_label">
							{locateDiccionary['categories']}
						</b>
						<TouchableOpacity onPress={showCreateCategoryModal}>
							<View className="left_drawer_addIconWrapper">
								<img src={IconAdd} className="left_drawer_rightButtonIcon" />
							</View>
						</TouchableOpacity>
					</View>
					<View className="left_drawer_left_drawer_categories_container">
						{categories.map((category) => {
							return (
								<CategoryDrawerOption
									key={category.id}
									category={category}
									hasMenuOptions={category.default}
								/>
							)
						})}
					</View>

					<View className="left_drawer_categoriesTitleContainer">
						<b className="left_drawer_label">{locateDiccionary['tags']}</b>
					</View>
					<View className="left_drawer_tagscontainer">
						{tags.map((tag) => {
							return <TagDrawerOption key={tag.name} tag={tag} />
						})}
					</View>
				</ScrollView>
			</SafeAreaView>
		</View>
	)
}

export default LeftDrawer
