import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Category from '../../models/interfaces/Category'
import LocalizationSelector from '../../localization/localizationSelector'

const locateDiccionary = LocalizationSelector.getLocalizationDiccionary()
const initialState: Category[] = [
	{
		id: '0',
		name: locateDiccionary['all'],
		color: 'transparent',
		selected: true,
		default: true
	}
]

const categoriesSlice = createSlice({
	name: 'categories',
	initialState: initialState,
	reducers: {
		setCategories: (_state, action: PayloadAction<Category[]>) => {
			return [...initialState, ...action.payload]
		},
		addCategory: (state, action: PayloadAction<Category>) => {
			state.push(action.payload)
		},
		updateCategory: (state, action: PayloadAction<Category>) => {
			return state.map((category) => {
				if (category.id === action.payload.id) {
					return action.payload
				} else {
					return category
				}
			})
		},
		removeCategory: (state, action: PayloadAction<string>) => {
			return state.filter((item) => item.id !== action.payload)
		},
		setCategorySelected: (state, action: PayloadAction<Category>) => {
			let _categories = state
			let __categories: Category[] = []

			_categories.map((category) => {
				if (JSON.stringify(category) === JSON.stringify(action.payload)) {
					category.selected = true
				} else {
					category.selected = false
				}
				__categories.push(category)
			})
			state = __categories
		},
		resetCategories: (state) => {
			return initialState
		}
	}
})

export const {
	setCategories,
	addCategory,
	removeCategory,
	updateCategory,
	setCategorySelected,
	resetCategories
} = categoriesSlice.actions

export default categoriesSlice.reducer
