const en = {
	login: 'Login',
	email: 'Email',
	password: 'Password',
	dont_have_account: 'Do not have an account?',
	signup: 'Sign up',
	continue_without_login: 'Continue without login',
	name: 'Name',
	complete_all_fields: 'Please complete all fields',
	email_already_registered: 'Email aready registered or incorrect information',
	wrong_password: 'Wrong password',
	invalid_credentials: 'Invalid Crendentials',
	profile: 'Profile',
	logout: 'Logout',
	close: 'Close',
	done: 'Done',
	type_your_email_please: 'Type your email please',
	user_not_found: 'User not found',
	error_sending_forgot_email: 'Error when trying to sent recover email',
	forgot_password: 'Forgot password',
	lets_recover_account: 'Lets recover your account',
	reset_password: 'Reset password',
	if_email_exists: 'If emails exists, email has been sent',
	type_email_and_password: 'Type your email and password',
	logged_in: 'Logged in',
	unable_to_retrieve_user_data: 'Unable to retrieve user data',
	email_or_password_invalid: 'Email or password are invalid',
	error_trying_to_logging: 'Error trying to log in',
	continue_taking_notes: 'Continue taking your notes',
	empty: 'Empty',
	start_taking_note_left: 'Start taking notes clicking on ',
	button: ' button!',
	notes: 'Notes',
	error_trying_to_register: 'Error when registering user',
	email_invalid_format: 'Error invalid email format',
	password_too_week: 'Password too weak, should be at least 6 characters',
	error_registering: 'Error trying to registering',
	signup_now: 'Sign Up now',
	and_start_writing: 'And start writing your notes',
	fullname: 'Fullname',
	select_category: 'Select Category',
	title: 'Title',
	type_something: 'Type something...',
	create: 'Create',
	edit: 'Edit',
	category: 'Category',
	enter_name: 'Enter name',
	color: 'Color',
	save: 'Save',
	cancel: 'Cancel',
	are_you_sure_delete: 'Are you sure you want to delete?',
	yes: 'Yes',
	categories: 'Categories',
	tags: 'Tags',
	add_new_category: 'Add new Category',
	account: 'Account',
	add_tags: 'Add tags',
	write_note: 'Write note',
	all: 'All',
	uncategorized: 'Uncategorized',
	settings: 'Settings',
	slogan:
		'Taking note App • Write, Paste and read your notes everywhere with Somenotes',
	developer: 'Developer: Luis Quijada',
	visualization_style: 'Visualization style',
	classic: 'Classic',
	tag: 'Tag',
	none: 'None',
	new_note: 'New Note',
	delete: 'Delete',
	edit_note: 'Edit Note',
	login_s_signup: 'Login / Sign Up',
	information: 'Information'
}
const es = {
	login: 'Iniciar Sesión',
	email: 'Correo electrónico',
	password: 'Contraseña',
	dont_have_account: '¿No tienes una cuenta?',
	signup: 'Registrarse',
	continue_without_login: 'Continuar sin cuenta',
	name: 'Nombre',
	complete_all_fields: 'Por favor completa todos los campos',
	email_already_registered:
		'Correo electrónico ya ha sido registrado o información incompleta',
	wrong_password: 'Contraseña incorrecta',
	invalid_credentials: 'Credenciales Inválidas',
	profile: 'Perfil',
	logout: 'Cerrar Sesión',
	close: 'Cerrar',
	done: 'Ok',
	type_your_email_please: 'Ingresa tu correo electronico por favor',
	user_not_found: 'Usuario no encontrado',
	error_sending_forgot_email: 'Error al intentar enviar correo de recuperación',
	forgot_password: 'Olvidé mi contraseña',
	lets_recover_account: 'Vamos a recuperar tu cuenta',
	reset_password: 'Restablecer contraseña',
	if_email_exists:
		'Si el correo electrónico existe, el correo de recuperación ha sido enviado',
	type_email_and_password: 'Ingresa tu correo electrónico y contraseña',
	logged_in: 'Sesión iniciada',
	unable_to_retrieve_user_data: 'No ha sido posible obtener datos del usuario',
	email_or_password_invalid: 'Correo electrónico o contraseña invalidos',
	error_trying_to_logging: 'Error al intentar iniciar sesión',
	continue_taking_notes: 'Continua guardando tus notas',
	empty: 'Vacío',
	start_taking_note_left: 'Comienza a escribir tus notas presionando el ',
	button: ' botón!',
	notes: 'Notas',
	error_trying_to_register: 'Error al intentar registrar usuario',
	email_invalid_format: 'Correo electrónico en formato inválido',
	password_too_week: 'Contraseña muy débil, almenos 6 caractéres',
	error_registering: 'Error al intentar registrar usuario',
	signup_now: 'Registrate ya',
	and_start_writing: 'Y comienza a escribir tus notas',
	fullname: 'Nombre completo',
	select_category: 'Selecciona Categoria',
	title: 'Título',
	type_something: 'Escribe algo ...',
	create: 'Crear',
	edit: 'Editar',
	category: 'Categoría',
	enter_name: 'Ingresa nombre',
	color: 'Color',
	save: 'Guardar',
	cancel: 'Cancelar',
	are_you_sure_delete: 'Estás seguro que quieres borrar?',
	yes: 'Si',
	categories: 'Categorias',
	tags: 'Etiquetas',
	add_new_category: 'Agregar nueva categoría',
	account: 'Mi cuenta',
	add_tags: 'Agregar etiquetas',
	write_note: 'Escribir nota',
	all: 'Todas',
	uncategorized: 'Sin categoría',
	settings: 'Preferencias',
	slogan:
		'App para tomar notas • Escriba, pegue y lea sus notas en todas partes con Somenotes',
	developer: 'Desarrollador: Luis Quijada',
	visualization_style: 'Estilo de Visualización',
	classic: 'Clásica',
	tag: 'Etiqueta',
	none: 'Ninguna',
	new_note: 'Nueva Nota',
	delete: 'Borrar',
	edit_note: 'Editar Nota',
	login_s_signup: 'Iniciar Sesión / Registrarse',
	information: 'Información'
}
export { en, es }
