import React from 'react'
import Modal from '../../../primitive/modal'
import View from '../../../primitive/view'

// Styles
import './styles.css'

// Images
const img_user_happy_face = require('../../../../assets/images/faces/img_face_thinking.png')

type ModalHelpProps = {
	visible: boolean
	onCancel: () => void
}

const ModalHelp: React.FC<ModalHelpProps> = ({ visible, onCancel }) => {
	return (
		<Modal
			visible={visible}
			animationType="fade"
			transparent={true}
			className="help_modalcontainers"
		>
			<View className="help_modalscrollView">
				<span
					className="modal_create_edit_note_close"
					onClick={() => {
						onCancel()
					}}
				>
					&times;
				</span>
				<img
					className="help_modalprofileImage"
					src={img_user_happy_face}
					style={{ opacity: 1 }}
				/>

				<b className="help_modalprofileName">Somenotes</b>
				<b className="help_modalprofileSurname">
					Taking note App • Write, paste and read your notes everywhere with
					Somenotes
				</b>
				<b className="help_modalprofileSurname">Developer: Luis Quijada</b>
				<b className="help_modalprofileEmail">Elesteam</b>
			</View>
		</Modal>
	)
}

export default ModalHelp
