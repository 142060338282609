import React, { useCallback, useEffect, useMemo, useState } from 'react'
import colorsPalette from '../../../../values/colorsPalette'
import { useAppSelector } from '../../../../redux/hooks'
import LocalizationSelector from '../../../../localization/localizationSelector'
import Modal from '../../../primitive/modal'
import View from '../../../primitive/view'
import TouchableOpacity from '../../../primitive/touchableOpacity'

// Styles
import './styles.css'
import Colors from '../../../../assets/styles/css/general/colors'

type CategoryTagModalProps = {
	visible: boolean
	onSave: (
		name: string,
		color: string,
		modifyingCategory?: boolean,
		modifyingCategoryId?: string
	) => void
	onCancel: () => void
	colorSelector?: boolean
}

type CategoryTagModalState = {
	name: string
	color: string
}

const CreateCategoryModal: React.FC<CategoryTagModalProps> = ({
	visible,
	onSave,
	onCancel,
	colorSelector = false
}) => {
	const [state, setState] = useState<CategoryTagModalState>({
		name: '',
		color: ''
	})
	const modification = useAppSelector((state) => state.modification)
	const [modifyingCategory, setModifyingCategory] = useState<boolean>()
	const locateDiccionary = LocalizationSelector.getLocalizationDiccionary()

	const handleSave = useCallback(() => {
		onSave(
			state.name,
			state.color,
			modifyingCategory,
			modification.category?.id
		)
		setState({ name: '', color: '' })
	}, [state, modifyingCategory, modification])

	useEffect(() => {
		if (modification && modification.type === 'category') {
			setModifyingCategory(true)
			setState({
				...state,
				name: modification.category?.name ?? '',
				color: modification.category?.color ?? ''
			})
		}
	}, [modification])

	const buttonDisabled = useMemo(() => {
		return (
			state.name.length === 0 || (colorSelector && state.color.length === 0)
		)
	}, [state, colorSelector])

	return (
		<Modal
			visible={visible}
			animationType="fade"
			transparent={true}
			className="create_category_modal_containers"
		>
			<View className="create_category_modal_modalContainer">
				<View className="create_category_modal_modalCard">
					<b className="create_category_modal_modalTitle">
						{modifyingCategory
							? locateDiccionary['edit']
							: locateDiccionary['create']}{' '}
						{locateDiccionary['category']}
					</b>
					<View className="create_category_modal_modalInputContainer">
						<b className="create_category_modal_label">
							{locateDiccionary['name']}
						</b>
						<input
							className="create_category_modal_textInputLeft"
							value={state.name}
							onChange={(val) => setState({ ...state, name: val.target.value })}
							placeholder={locateDiccionary['enter_name']}
						/>
					</View>
					{colorSelector && (
						<View className="create_category_modal_colorContainer">
							<b className="create_category_modal_label">
								{locateDiccionary['color']}
							</b>
							<View className="create_category_modal_colorsPalleteContainer">
								{colorsPalette.map((item) => (
									<TouchableOpacity
										key={item.id}
										onPress={() =>
											setState({ ...state, color: item.backgroundColor })
										}
									>
										<View
											className="create_category_modal_colorItem"
											style={{
												backgroundColor: item.backgroundColor,
												borderColor:
													item.backgroundColor === state.color
														? '#777777'
														: 'transparent',
												borderWidth:
													item.backgroundColor === state.color ? 4 : 0
											}}
										/>
									</TouchableOpacity>
								))}
							</View>
						</View>
					)}
					<View className="create_category_modal_modalButtonContainer">
						<TouchableOpacity
							className="create_category_modal_modal-button-secondary"
							onPress={onCancel}
						>
							<b className="create_category_modal_modal-button-secondary">
								{locateDiccionary['cancel']}
							</b>
						</TouchableOpacity>
						<TouchableOpacity
							className="create_category_modal_modal-button_container"
							style={{
								opacity: buttonDisabled ? 0.5 : 1,
								backgroundColor: buttonDisabled
									? Colors.WHITE_OPACITY_50
									: Colors.PRIMARY_COLOR
							}}
							onPress={handleSave}
							disabled={buttonDisabled}
						>
							<b
								className="create_category_modal_modal-button"
								style={{
									opacity: buttonDisabled ? 0.5 : 1,
									backgroundColor: buttonDisabled
										? Colors.SHADOW_COLOR_FIXED
										: Colors.PRIMARY_COLOR
								}}
							>
								{locateDiccionary['save']}
							</b>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</Modal>
	)
}

export default CreateCategoryModal
