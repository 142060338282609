import React, { CSSProperties, ReactNode } from 'react'

interface ScrollViewProps {
	children: ReactNode
	style?: CSSProperties
	showsHorizontalScrollIndicator?: boolean
	showsVerticalScrollIndicator?: boolean
	className?: string
}

const ScrollView: React.FC<ScrollViewProps> = ({
	children,
	style,
	className
}) => {
	const mergedStyle: CSSProperties = {
		...style
	}
	return (
		<div style={mergedStyle} className={className}>
			{children}
		</div>
	)
}

export default ScrollView
