import { useCallback } from 'react'

import Tag from '../../../models/interfaces/Tag'
import { useAppDispatch } from '../../../redux/hooks'
import { setTagSelected } from '../../../redux/slices/tagsSlice'
import TouchableOpacity from '../../primitive/touchableOpacity'
import View from '../../primitive/view'

// Styles
import './styles.css'
import Colors from '../../../assets/styles/css/general/colors'

const TagDrawerOption = (props: { tag: Tag }) => {
	const dispatch = useAppDispatch()

	const setIsChecked = useCallback(() => {
		dispatch(setTagSelected(props.tag))
	}, [props.tag, setTagSelected])

	return (
		<TouchableOpacity onPress={setIsChecked}>
			<View
				className="tag_drawer_option_container"
				style={{
					backgroundColor: props.tag.selected
						? Colors.PRIMARY_COLOR
						: Colors.VIEW_TAG
				}}
			>
				<View className="tag_drawer_option_containerName">
					<b
						className="tag_drawer_option_hastagsymbol"
						style={{
							color: props.tag.selected
								? Colors.PRIMARY_LIGHTTODARK_COLOR
								: Colors.PRIMARY_TEXT
						}}
					>
						#
					</b>
					<b
						className="tag_drawer_option_name"
						style={{
							color: props.tag.selected
								? Colors.PRIMARY_LIGHTTODARK_COLOR
								: Colors.PRIMARY_TEXT
						}}
					>
						{props.tag.name}
					</b>
				</View>
			</View>
		</TouchableOpacity>
	)
}

export default TagDrawerOption
