import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import tagsSlice from './slices/tagsSlice'
import categoriesSlice from './slices/categoriesSlice'
import notesSlice from './slices/notesSlice'
import visualizationSlice from './slices/visualizationSlice'
import modalsVisiblesSlice from './slices/modalsVisiblesSlice'
import deletionSlice from './slices/deletionSlice'
import additionSlice from './slices/additionSlice'
import modificationSlice from './slices/modificationSlice'
import controlSlice from './slices/controlSlice'

export const store = configureStore({
	reducer: {
		user: userReducer,
		tags: tagsSlice,
		categories: categoriesSlice,
		notes: notesSlice,
		visualization: visualizationSlice,
		modalsvisibles: modalsVisiblesSlice,
		deletion: deletionSlice,
		addition: additionSlice,
		modification: modificationSlice,
		control: controlSlice
	},
	devTools: process.env.NODE_ENV !== 'production'
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
