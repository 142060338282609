import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
	apiKey: 'AIzaSyDRNqkAEB9gBwON6RrVPYNxzv6iVAlTPvc',
	authDomain: 'somenotes-83ca8.firebaseapp.com',
	databaseURL: 'https://somenotes-83ca8.firebaseio.com',
	projectId: 'somenotes-83ca8',
	storageBucket: 'somenotes-83ca8.appspot.com',
	messagingSenderId: '397193560317',
	appId: '1:397193560317:web:8f42e646a0f132c3a4be94',
	measurementId: 'G-4GKK1CEVSC'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
const database = getDatabase(app)

export { app, analytics, auth, database }
