import React, { CSSProperties, ReactNode } from 'react'

interface ViewProps {
	children?: ReactNode
	style?: CSSProperties
	className?: string
	onMouseEnter?: () => void
	onMouseLeave?: () => void
}

const View: React.FC<ViewProps> = ({
	children,
	style,
	className,
	onMouseEnter,
	onMouseLeave
}) => {
	const mergedStyle: CSSProperties = {
		...style
	}
	return (
		<div
			className={className}
			style={mergedStyle}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{children}
		</div>
	)
}

export default View
