interface colors {
	id: string
	backgroundColor: string
	textColor: string
}

const colorsPalette: colors[] = [
	{
		id: '0',
		backgroundColor: '#ff7f2a',
		textColor: 'black'
	},
	{
		id: '1',
		backgroundColor: '#ff9955',
		textColor: 'black'
	},
	{
		id: '2',
		backgroundColor: '#aa4400',
		textColor: 'black'
	},
	{
		id: '3',
		backgroundColor: '#ff0000',
		textColor: '#ffffff'
	},
	{
		id: '4',
		backgroundColor: '#ffd42a',
		textColor: '#ffffff'
	},
	{
		id: '5',
		backgroundColor: '#d3bc5f',
		textColor: '#ffffff'
	},
	{
		id: '6',
		backgroundColor: '#abc837',
		textColor: '#ffffff'
	},
	{
		id: '7',
		backgroundColor: '#2aff2a',
		textColor: '#ffffff'
	},
	{
		id: '8',
		backgroundColor: '#00aa00',
		textColor: '#ffffff'
	},
	{
		id: '9',
		backgroundColor: '#00d455',
		textColor: '#ffffff'
	},
	{
		id: '10',
		backgroundColor: '#37c8ab',
		textColor: '#ffffff'
	},
	{
		id: '11',
		backgroundColor: '#00ccff',
		textColor: '#ffffff'
	},
	{
		id: '12',
		backgroundColor: '#55e5ff',
		textColor: '#ffffff'
	},
	{
		id: '13',
		backgroundColor: '#5fbcd3',
		textColor: '#ffffff'
	},
	{
		id: '14',
		backgroundColor: '#0013bb',
		textColor: '#ffffff'
	},
	{
		id: '15',
		backgroundColor: '#0066ff',
		textColor: '#ffffff'
	},
	{
		id: '16',
		backgroundColor: '#5555ff',
		textColor: '#ffffff'
	},
	{
		id: '17',
		backgroundColor: '#8a6cb8',
		textColor: '#ffffff'
	},
	{
		id: '18',
		backgroundColor: '#cc00ff',
		textColor: '#ffffff'
	},
	{
		id: '19',
		backgroundColor: '#ff00cc',
		textColor: '#ffffff'
	},
	{
		id: '20',
		backgroundColor: '#3c3b57',
		textColor: '#ffffff'
	}
]

export default colorsPalette
