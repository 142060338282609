import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Tag from '../../models/interfaces/Tag'
import LocalizationSelector from '../../localization/localizationSelector'

const locateDiccionary = LocalizationSelector.getLocalizationDiccionary()

const initialState: Tag[] = [
	{
		name: locateDiccionary['all'],
		selected: true,
		default: true
	}
]

const tagsSlice = createSlice({
	name: 'tags',
	initialState: initialState,
	reducers: {
		setTags: (_state, action: PayloadAction<Tag[]>) => {
			return [initialState[0], ...action.payload]
		},
		addTag: (state, action: PayloadAction<Tag>) => {
			state.push(action.payload)
		},
		removeTag: (state, action: PayloadAction<string>) => {
			return state.filter((item) => item.name !== action.payload)
		},
		setTagSelected: (state, action: PayloadAction<Tag>) => {
			let _tags = state
			let __tags: Tag[] = []

			_tags.map((tag) => {
				if (JSON.stringify(tag) === JSON.stringify(action.payload)) {
					tag.selected = true
				} else {
					tag.selected = false
				}
				__tags.push(tag)
			})
			state = __tags
		},
		resetTags: (state) => {
			return initialState
		}
	}
})

export const { setTags, addTag, removeTag, setTagSelected, resetTags } =
	tagsSlice.actions

export default tagsSlice.reducer
