import { useCallback, useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import {
	EditorState,
	ContentState,
	convertToRaw,
	convertFromHTML
} from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import LocalizationSelector from '../../../../localization/localizationSelector'
import { database } from '../../../../../firebaseConfig'
import { push, ref, set } from 'firebase/database'
import { addNote, updateNote } from '../../../../redux/slices/notesSlice'
import { resetAddition } from '../../../../redux/slices/additionSlice'
import View from '../../../primitive/view'
import TouchableOpacity from '../../../primitive/touchableOpacity'
import CatagView from '../../../atoms/catagView'
import SelectCategoryModal from '../selectCategoryModal'
import { setDeletion } from '../../../../redux/slices/deletionSlice'
import { setDeleteModalVisible } from '../../../../redux/slices/modalsVisiblesSlice'
import LoadingSpinner from '../../../atoms/loadingSpinner'
import Note from '../../../../models/interfaces/Note'

// Styles
import '../styles.css'
import './styles.css'
import richTextToolbarOptions from '../../../../utils/richTextToolbarOptions'

// Images
const RemoveTagIcon = require('../../../../assets/images/buttons/btn_x_remove_grey.png')
const DeleteIcon = require('../../../../assets/images/buttons/icon_delete.png')

interface ModalCreateEditNoteProps {
	note?: Note
	hideModalService: () => any
}

const ModalCreateEditNote = ({
	hideModalService: hideModal,
	note: passedNote
}: ModalCreateEditNoteProps) => {
	// STATES
	const [loading, setLoading] = useState<boolean>(false)
	const [message, setMessage] = useState<string>('')
	const [content, setContent] = useState('')
	const contentRef = useRef('')
	const [title, setTitle] = useState('')
	const titleRef = useRef('')
	const [tags, setTags] = useState<string[]>([])
	const tagsRef = useRef<string[]>([])
	const [selectedCategoryId, setSelectedCategoryId] = useState<string>()
	const [selectedCategoryName, setSelectedCategoryName] = useState<string>()
	const [selectedCategoryColor, setSelectedCategoryColor] = useState<string>()
	const selectedCategoryIdRef = useRef('')
	const addition = useAppSelector((state) => state.addition)
	const categories = useAppSelector((state) => state.categories)
	const avoidSave = useAppSelector((state) => state.control.avoidSave)
	const editingNote = useRef(
		passedNote && passedNote.id !== '' ? passedNote : undefined
	)
	const user = useAppSelector((state) => state.user)
	const locateDiccionary = LocalizationSelector.getLocalizationDiccionary()
	const textareaTitleRef = useRef<HTMLTextAreaElement>(null)
	const textareaContentRef = useRef(null)
	const yaTitleHeightAdjustment = useRef(false)
	const [editorState, setEditorState] = useState<any>(
		EditorState.createWithContent(ContentState.createFromText(''))
	)
	const [textTag, setTextTag] = useState<string>('')
	const [selectCategoryVisible, setSelectCategoryVisible] = useState(false)

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (editingNote.current) {
			setTitle(editingNote.current.title)
			setContent(editingNote.current.content)
			const blocksFromHTML = convertFromHTML(editingNote.current.content)
			const contentState = ContentState.createFromBlockArray(
				blocksFromHTML.contentBlocks,
				blocksFromHTML.entityMap
			)

			setEditorState(EditorState.createWithContent(contentState))

			setTags(editingNote.current.tags ?? [])
			setSelectedCategoryId(editingNote.current.categoryId)
			categories.map((category) => {
				if (editingNote.current?.categoryId === category.id) {
					setSelectedCategoryName(category.name)
					setSelectedCategoryColor(category.color)
				}
			})
		} else {
			setEditorState(
				EditorState.createWithContent(ContentState.createFromText(''))
			)
		}
	}, [])

	const handleTextChange = useCallback(
		(textTag: string) => {
			setTextTag(textTag)
			const lastChar = textTag.charAt(textTag.length - 1)
			if (lastChar === ',' || lastChar === ' ') {
				const tag = textTag.slice(0, -1)
				if (tag.length > 0) {
					setTags([...tags, tag])
					setTextTag('')
				}
			}
		},
		[tags]
	)

	useEffect(() => {
		if (
			!yaTitleHeightAdjustment.current &&
			textareaTitleRef &&
			textareaTitleRef.current
		) {
			textareaTitleRef.current.style.height = '1px'
			textareaTitleRef.current.style.height = `${textareaTitleRef.current.scrollHeight}px`
		}
	})

	const selectCategory = useCallback(() => {
		setSelectCategoryVisible(true)
	}, [setSelectCategoryVisible])

	const handleTagDelete = useCallback(
		(index: number) => {
			const updatedTags = [...tags]
			updatedTags.splice(index, 1)
			setTags(updatedTags)
		},
		[tags]
	)

	const onSelectCategoryCancel = useCallback(() => {
		setSelectCategoryVisible(false)
	}, [setSelectCategoryVisible])

	const onSaveSelectedCategory = useCallback(
		(
			newSelectedCategoryId?: string,
			newSelectedCategoryName?: string,
			newSelectedCategoryColor?: string
		) => {
			setSelectedCategoryId(newSelectedCategoryId)
			setSelectedCategoryName(newSelectedCategoryName)
			setSelectedCategoryColor(newSelectedCategoryColor)
			setSelectCategoryVisible(false)
		},
		[]
	)

	useEffect(() => {
		titleRef.current = title
	}, [title])

	useEffect(() => {
		contentRef.current = content
	}, [content])

	useEffect(() => {
		tagsRef.current = tags
	}, [tags])

	useEffect(() => {
		const aaa = draftToHtml(convertToRaw(editorState.getCurrentContent()))
		setContent(aaa)
	}, [editorState])

	useEffect(() => {
		selectedCategoryIdRef.current = selectedCategoryId ?? ''
	}, [selectedCategoryId])

	const saveNote = useCallback(() => {
		console.log(
			`contentRef.current |=========>`,
			JSON.stringify(contentRef.current)
		)
		if (avoidSave) {
			return
		}

		const currentDate = new Date()
		const formattedDate = currentDate
			.toISOString()
			.slice(0, 19)
			.replace('T', ' ')

		if (
			contentRef.current.length > 0 &&
			titleRef.current.length > 0 &&
			contentRef.current !== '<p></p>\n'
		) {
			const randomInteger = Math.floor(Math.random() * 100)
			const randomString = randomInteger.toString()
			if (editingNote.current) {
				const databaseReference = ref(
					database,
					'/notes/' + user.uid + '/' + editingNote.current.id
				)

				contentRef.current = contentRef.current.replaceAll(
					'public-DraftStyleDefault-block',
					''
				)

				contentRef.current = contentRef.current.replaceAll(
					'  ',
					'<span> ‎</span>'
				)
				contentRef.current = contentRef.current.replaceAll(
					'&nbsp;',
					'<span> ‎</span>'
				)
				contentRef.current = contentRef.current.replaceAll('&nbsp;', '‎')
				contentRef.current = contentRef.current.replaceAll(
					'<p></p>\n',
					'<p>‎</p>'
				)
				contentRef.current = contentRef.current.replaceAll(
					'<p></p>\t',
					'<p>-></p>'
				)

				set(databaseReference, {
					title: titleRef.current,
					content: contentRef.current,
					tags: tagsRef.current,
					categoryId: selectedCategoryIdRef.current,
					modificationDate: formattedDate
				})
					.then((key) => {
						if (editingNote.current) {
							dispatch(
								updateNote({
									id: editingNote.current.id,
									title: titleRef.current,
									content: contentRef.current,
									tags: tagsRef.current,
									categoryId: selectedCategoryIdRef.current,
									modificationDate: formattedDate
								})
							)
						}
					})
					.catch((err) => {
						console.log(`err 4|=========>`, JSON.stringify(err))
					})
			} else {
				const databaseReference = ref(database, '/notes/' + user.uid + '/')

				contentRef.current = contentRef.current.replaceAll(
					'public-DraftStyleDefault-block',
					''
				)

				push(databaseReference, {
					title: titleRef.current,
					content: contentRef.current,
					tags: tagsRef.current,
					categoryId: selectedCategoryIdRef.current,
					modificationDate: formattedDate
				})
					.then((key) => {
						editingNote.current = {
							id: key.key ?? randomString,
							title: titleRef.current,
							content: contentRef.current,
							tags: tagsRef.current,
							categoryId: selectedCategoryIdRef.current,
							modificationDate: formattedDate
						}
						dispatch(
							addNote({
								id: key.key ?? randomString,
								title: titleRef.current,
								content: contentRef.current,
								tags: tagsRef.current,
								categoryId: selectedCategoryIdRef.current,
								modificationDate: formattedDate
							})
						)
					})
					.catch((err) => {
						console.log(`err 5|=========>`, JSON.stringify(err))
					})
			}
		}
	}, [
		contentRef.current,
		editingNote.current,
		titleRef.current,
		contentRef.current,
		selectedCategoryIdRef.current,
		tagsRef.current,
		user,
		editorState,
		avoidSave,
		avoidSave
	])

	const handleHideModal = useCallback(() => {
		if (!avoidSave) {
			saveNote()
		}
		hideModal && hideModal()
	}, [saveNote, hideModal, avoidSave])

	useEffect(() => {
		const intervalId = setInterval(saveNote, 60000)

		return () => clearInterval(intervalId)
	}, [])

	const onTagsLosesFocus = useCallback(() => {
		if (textTag.length > 0) {
			const tag = textTag.slice(0, -1)
			setTags([...tags, tag])
			setTextTag('')
		}
	}, [textTag, tags])

	useEffect(() => {
		if (addition.type === 'category') {
			setSelectedCategoryId(addition.id)
			let _name = ''
			let _color = ''
			categories.forEach((category) => {
				if (category.id === addition.id) {
					_name = category.name
					_color = category.color
				}
			})
			setSelectedCategoryName(_name)
			setSelectedCategoryColor(_color)
		}
	}, [addition])

	const handleTitleInputChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setTitle(event.target.value)

		// Auto-resize the textarea
		if (textareaTitleRef.current) {
			textareaTitleRef.current.style.height = '1px'
			textareaTitleRef.current.style.height = `${textareaTitleRef.current.scrollHeight}px`
			yaTitleHeightAdjustment.current = true
		}
	}

	const handleTextAreaKeyDown = (
		event: React.KeyboardEvent<HTMLTextAreaElement>,
		nextTextAreaRef: any
	) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault()
			if (nextTextAreaRef.current) {
				nextTextAreaRef.current.focus()
			}
		}
	}

	const onDeleteClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (editingNote.current) {
			dispatch(
				setDeletion({
					type: 'note',
					id: editingNote.current.id
				})
			)
			dispatch(setDeleteModalVisible(true))
		}

		event.stopPropagation()
	}

	const onEditorStateChange = (editorState: any) => {
		setEditorState(editorState)
	}

	return (
		<div id="myModal" className="modal">
			<div className="modal_create_edit_note_modal-content">
				<span
					className="modal_create_edit_note_close"
					onClick={() => {
						handleHideModal()
					}}
				>
					&times;
				</span>
				<h1 className="modal_create_edit_note_modal-content-title">
					{' '}
					{editingNote.current
						? locateDiccionary['edit_note'].toUpperCase()
						: locateDiccionary['new_note'].toUpperCase()}
				</h1>

				<View className="backgroundView">
					<View className="modal_create_edit_note_container">
						<View className="modal_create_edit_note_category_view">
							<TouchableOpacity
								className="modal_create_edit_note_category_view_items_button"
								onPress={selectCategory}
							>
								<View className="modal_create_edit_note_category_view_items_button_view">
									<CatagView
										text={
											selectedCategoryName && selectedCategoryId !== ''
												? selectedCategoryName
												: locateDiccionary['select_category']
										}
										dot={true}
										backgroundColor={
											selectedCategoryName && selectedCategoryId !== ''
												? selectedCategoryColor
												: '#afafaf52'
										}
									/>
								</View>
							</TouchableOpacity>
						</View>
						<textarea
							readOnly={loading}
							ref={textareaTitleRef}
							placeholder={locateDiccionary['title']}
							value={title}
							onChange={handleTitleInputChange}
							className="modal_create_edit_note_titleinput"
							onKeyDown={(event) =>
								handleTextAreaKeyDown(event, textareaContentRef)
							}
						></textarea>
						<Editor
							editorRef={(ref: any) => {
								textareaContentRef.current = ref
							}}
							editorState={editorState}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							onEditorStateChange={onEditorStateChange}
							readOnly={loading}
							placeholder={locateDiccionary['type_something']}
							toolbar={richTextToolbarOptions}
						/>
						<View className="modal_create_edit_note_tagcategory_view">
							<View className="modal_create_edit_note_tags_view modal_create_edit_note_tagcategory_view_item">
								<View className="modal_create_edit_note_tagscontainer">
									{tags.map((tag, index) => (
										<View className="modal_create_edit_note_tag" key={tag}>
											<b className="modal_create_edit_note_tagText_hash">#</b>
											<b className="modal_create_edit_note_tagText_tag">
												{tag}
											</b>
											<TouchableOpacity
												onPress={() => handleTagDelete(index)}
												className="modal_create_edit_note_tagDelete"
											>
												<img
													src={RemoveTagIcon}
													className="modal_create_edit_note_tagDeleteText"
												/>
											</TouchableOpacity>
										</View>
									))}
									<input
										className="modal_create_edit_note_input"
										value={textTag}
										onChange={(val) => {
											handleTextChange(val.target.value)
										}}
										placeholder={locateDiccionary['add_tags']}
										spellCheck={false}
										onBlur={onTagsLosesFocus}
									/>
								</View>
							</View>
						</View>
					</View>
				</View>

				<View className="modal_create_edit_note_bottom_buttons_container">
					<View className="modal_create_edit_note_modal-btn-options-container">
						{editingNote.current && (
							<button
								className="modal_create_edit_note_modal-button-secondary"
								onClick={(event) => {
									onDeleteClick(event)
								}}
							>
								<img
									src={DeleteIcon}
									className="modal_create_edit_note_modal-btn-image"
								/>
							</button>
						)}
						<b className="modal_create_edit_note_modal-btn-date">
							{/* Edited 20/12/2023 10:32 PM */}
						</b>
					</View>

					<button
						className="modal_create_edit_note_modal-button"
						onClick={() => {
							handleHideModal()
						}}
					>
						{locateDiccionary['close']}
					</button>
				</View>

				<b className="modal_create_edit_note_message_b_error">{message}</b>

				{loading ? (
					<div className="modal_create_edit_note_div_spinner_container">
						<LoadingSpinner />
					</div>
				) : (
					<></>
				)}
			</div>
			{selectCategoryVisible && (
				<View className="modal_create_edit_note_modal_container_select_category">
					<SelectCategoryModal
						visible={selectCategoryVisible}
						onCancel={onSelectCategoryCancel}
						onSave={onSaveSelectedCategory}
						selectedCategory={selectedCategoryId}
					/>
				</View>
			)}
		</div>
	)
}

export default ModalCreateEditNote
