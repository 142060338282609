import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Visualization from '../../models/interfaces/Visualization'

const initialState: Visualization = {
	visualization: 'full-list'
}

const visualizationSlice = createSlice({
	name: 'visualization',
	initialState: initialState,
	reducers: {
		setVisualization: (
			state,
			action: PayloadAction<'full-list' | 'category-list' | 'tag-list'>
		) => {
			state.visualization = action.payload
		},
		resetVisualization: (state) => {
			state.visualization = initialState.visualization
		}
	}
})

export const { setVisualization, resetVisualization } =
	visualizationSlice.actions
export default visualizationSlice.reducer
