import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Addition } from '../../models/interfaces/Addition'

const initialState: Addition = {
	type: '',
	id: ''
}

const additionSlice = createSlice({
	name: 'addition',
	initialState: initialState,
	reducers: {
		setAddition: (state, action: PayloadAction<Addition>) => {
			state.type = action.payload.type
			state.id = action.payload.id
		},
		resetAddition: (state) => {
			state.id = initialState.id
			state.type = initialState.type
		}
	}
})

export const { setAddition, resetAddition } = additionSlice.actions
export default additionSlice.reducer
