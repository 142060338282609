import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import LocalizationSelector from '../../../../localization/localizationSelector'
import Modal from '../../../primitive/modal'
import View from '../../../primitive/view'
import TouchableOpacity from '../../../primitive/touchableOpacity'
import { ReactSession } from 'react-client-session'
import { resetUser } from '../../../../redux/slices/userSlice'
import { useNavigate } from 'react-router-dom'
import { resetAddition } from '../../../../redux/slices/additionSlice'
import { resetCategories } from '../../../../redux/slices/categoriesSlice'
import { resetTags } from '../../../../redux/slices/tagsSlice'
import { resetModalsVisibles } from '../../../../redux/slices/modalsVisiblesSlice'
import { resetModification } from '../../../../redux/slices/modificationSlice'
import { resetNotes } from '../../../../redux/slices/notesSlice'

// Styles
import './styles.css'

// Images
const img_user_happy_face = require('../../../../assets/images/faces/img_face_very_happy.png')

type ProfileModalModalProps = {
	visible: boolean
	onCancel: () => void
}

const ProfileModalModal: React.FC<ProfileModalModalProps> = ({
	visible,
	onCancel
}) => {
	const user = useAppSelector((state) => state.user)
	const dispatch = useAppDispatch()
	const locateDiccionary = LocalizationSelector.getLocalizationDiccionary()

	const navigate = useNavigate()

	function performLogout() {
		// clearing session
		ReactSession.remove('user')
		// clearing global state
		dispatch(resetUser())
		dispatch(resetAddition())
		dispatch(resetCategories())
		dispatch(resetTags())
		dispatch(resetModalsVisibles())
		dispatch(resetModification())
		dispatch(resetNotes())
		navigate('/login')
		onCancel()
	}

	return (
		<Modal
			visible={visible}
			animationType="fade"
			transparent={true}
			className="profile_modal_containers"
		>
			<View className="profile_modal_scrollView">
				<span
					className="modal_create_edit_note_close"
					onClick={() => {
						onCancel()
					}}
				>
					&times;
				</span>
				<img
					className="profile_modal_profileImage"
					src={img_user_happy_face}
					style={{ opacity: 1 }}
				/>

				<b className="profile_modal_profileName">{user.name}</b>
				<b className="profile_modal_profileSurname">{user.surname}</b>
				<b className="profile_modal_profileEmail">{user.email}</b>

				<TouchableOpacity
					className="profile_modal_btn_danger"
					onPress={() => performLogout()}
				>
					<b className="profile_modal_btn_danger_text">
						{locateDiccionary['logout']}
					</b>
				</TouchableOpacity>
			</View>
		</Modal>
	)
}

export default ProfileModalModal
