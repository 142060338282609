export default class User {
	email: string
	name: string
	surname: string
	uid: string
	active?: boolean
	//creationDate: Date,
	creationDateString?: string
	createdBy?: string
	//modificationDate: Date,
	modificationDateString?: string
	modifiedBy?: string
	photo_url?: string

	constructor(
		_email: string,
		_username: string,
		_name: string,
		_surname: string,
		_uid: string
	) {
		this.email = _email
		this.name = _name
		this.surname = _surname
		this.uid = _uid
	}
}
