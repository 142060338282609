const richTextToolbarOptions = {
	options: [
		'inline',
		'list',
		'textAlign',
		'link',
		'emoji',
		// 'image',
		'history'
	],
	inline: {
		inDropdown: false,
		className: undefined,
		component: undefined,
		dropdownClassName: undefined,
		options: [
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'monospace',
			'superscript',
			'subscript'
		],
		bold: { className: undefined },
		italic: { className: undefined },
		underline: { className: undefined },
		strikethrough: { className: undefined },
		monospace: { className: undefined },
		superscript: { className: undefined },
		subscript: { className: undefined }
	},
	list: {
		inDropdown: false,
		className: undefined,
		component: undefined,
		dropdownClassName: undefined,
		options: ['unordered', 'ordered', 'indent', 'outdent'],
		unordered: { className: undefined },
		ordered: { className: undefined },
		indent: { className: undefined },
		outdent: { className: undefined }
	},
	textAlign: {
		inDropdown: false,
		className: undefined,
		component: undefined,
		dropdownClassName: undefined,
		options: ['left', 'center', 'right', 'justify'],
		left: { className: undefined },
		center: { className: undefined },
		right: { className: undefined },
		justify: { className: undefined }
	},

	link: {
		inDropdown: false,
		className: undefined,
		component: undefined,
		popupClassName: undefined,
		dropdownClassName: undefined,
		showOpenOptionOnHover: true,
		defaultTargetOption: '_self',
		options: ['link', 'unlink'],
		link: { className: undefined },
		unlink: { className: undefined },
		linkCallback: undefined
	},
	emoji: {
		className: undefined,
		component: undefined,
		popupClassName: undefined,
		emojis: [
			'😀',
			'😁',
			'😂',
			'😃',
			'😉',
			'😋',
			'😎',
			'😍',
			'😗',
			'🤗',
			'🤔',
			'😣',
			'😫',
			'😴',
			'😌',
			'🤓',
			'😛',
			'😜',
			'😠',
			'😇',
			'😷',
			'😈',
			'👻',
			'😺',
			'😸',
			'😹',
			'😻',
			'😼',
			'😽',
			'🙀',
			'🙈',
			'🙉',
			'🙊',
			'👼',
			'👮',
			'🕵',
			'💂',
			'👳',
			'🎅',
			'👸',
			'👰',
			'👲',
			'🙍',
			'🙇',
			'🚶',
			'🏃',
			'💃',
			'⛷',
			'🏂',
			'🏌',
			'🏄',
			'🚣',
			'🏊',
			'⛹',
			'🏋',
			'🚴',
			'👫',
			'💪',
			'👈',
			'👉',
			'👉',
			'👆',
			'🖕',
			'👇',
			'🖖',
			'🤘',
			'🖐',
			'👌',
			'👍',
			'👎',
			'✊',
			'👊',
			'👏',
			'🙌',
			'🙏',
			'🐵',
			'🐶',
			'🐇',
			'🐥',
			'🐸',
			'🐌',
			'🐛',
			'🐜',
			'🐝',
			'🍉',
			'🍄',
			'🍔',
			'🍤',
			'🍨',
			'🍪',
			'🎂',
			'🍰',
			'🍾',
			'🍷',
			'🍸',
			'🍺',
			'🌍',
			'🚑',
			'⏰',
			'🌙',
			'🌝',
			'🌞',
			'⭐',
			'🌟',
			'🌠',
			'🌨',
			'🌩',
			'⛄',
			'🔥',
			'🎄',
			'🎈',
			'🎉',
			'🎊',
			'🎁',
			'🎗',
			'🏀',
			'🏈',
			'🎲',
			'🔇',
			'🔈',
			'📣',
			'🔔',
			'🎵',
			'🎷',
			'💰',
			'🖊',
			'📅',
			'✅',
			'❎',
			'💯'
		]
	},
	// image: {
	// 	className: undefined,
	// 	component: undefined,
	// 	popupClassName: undefined,
	// 	urlEnabled: true,
	// 	uploadEnabled: true,
	// 	alignmentEnabled: true,
	// 	uploadCallback: undefined,
	// 	previewImage: false,
	// 	inputAccept:
	// 		'image/gif,image/jpeg,image/jpg,image/png,image/svg',
	// 	alt: { present: false, mandatory: false },
	// 	defaultSize: {
	// 		height: 'auto',
	// 		width: 'auto'
	// 	}
	// },
	// remove: { className: undefined, component: undefined },
	history: {
		inDropdown: false,
		className: undefined,
		component: undefined,
		dropdownClassName: undefined,
		options: ['undo', 'redo'],
		undo: { className: undefined },
		redo: { className: undefined }
	}
}

export default richTextToolbarOptions
